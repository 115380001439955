import * as firebase from "firebase/app";
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyADmxLUGKnePv8DX6qM8DTQKM5biy1lmHE",
    authDomain: "gni-dctool.firebaseapp.com",
    databaseURL: "https://gni-dctool.firebaseio.com",
    projectId: "gni-dctool",
    storageBucket: "gni-dcp",
    messagingSenderId: "839457457502",
    appId: "1:839457457502:web:9224969e1f709f0562fbcf",
    measurementId: "G-091X0849GP"
};

const app = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

export default app;