import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core';
import Service from "../Networking/networkutils";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Headers from '../ContractorPlanning/Headers';
import GetChildTableData from './GetChildTableData';

const classes = (theme) => ({

});

class GetAllocationCentres extends Component {
    _isMounted = false;
    _url = null;
    constructor(props) {
        super(props);
        if (props.type === "ReinstatingAgent") {
            this._url = '/bb/api/precon2/se/?reinstating_agent=' + props.value;
        }
        else {
            this._url = '/bb/api/surface/category/?reinstating_crew=' + props.value;
        }
        this.state = { isLoadMainTable: true, data: [], URL: this._url };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getData();
    }
    componentWillUnmount() {
        this._isMounted = false;
        this._url = null;
    }
    callParentMethod = (rowData) => {
        this.props.handleChildEvent(true, rowData);
    }
    getData() {
        Service.get(this.state.URL)
            .then(res => {
                if (this._isMounted) {
                    //Surface Category Data
                    var workOrderNos = res.data.map(s => s.Work_Order);
                    if (workOrderNos.length) {
                        //Surface Category data with Pre-Con data
                        Service.get('/bb/api/planning2/noprecon/?Work_Order=' + workOrderNos.join()).then(res => {
                            this.setState({ data: res.data, isLoadMainTable: false });
                        }).catch(error => {
                            this.props.enqueueSnackbar('Something went wrong while fetching Surface Category !', { variant: 'error' });
                        });
                    } else {
                        this.setState({ Childdata: [], isLoadMainTable: false });
                    }
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                this.setState({ Childdata: [], isLoadMainTable: false });
            });
    }
    render() {
        return (
            <div className={classes.root}>
                <MaterialTable
                    title={this.props.title}
                    columns={[
                        { title: 'Work Order #', field: 'Work_Order', headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 } },
                        { title: 'Job Type', field: 'Job_Plan', lookup: Headers.lookupJobPlan, headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 } },
                        { title: 'Address', field: 'Full_Address', headerStyle: { minWidth: 1300 }, cellStyle: { minWidth: 1300 } },
                    ]}
                    isLoading={this.state.isLoadMainTable}
                    data={this.state.data}
                    options={{
                        pageSize: 20,
                        search: true,
                        maxBodyHeight: Math.round(window.screen.height - window.screen.height * 0.45),
                        filtering: true,
                        sorting: true,
                        doubleHorizontalScroll: true,
                        headerStyle: {
                            position: 'sticky',
                            top: 0
                        }
                    }}
                    actions={[
                        {
                            icon: AssignmentTurnedInIcon,
                            tooltip: 'Pre-Construction Form',
                            onClick: (event, rowData) => {
                                this.callParentMethod(rowData);
                            }
                        }
                    ]}
                    detailPanel={(rowData) => {
                        return (<GetChildTableData parentProps={rowData} />);
                    }}
                />
            </div >
        );
    }
}
export default withStyles(classes)(withSnackbar(GetAllocationCentres));