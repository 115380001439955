import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { withStyles, Grid } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import Service from "../Networking/networkutils";
import Headers from '../ContractorPlanning/Headers';
import { withSnackbar } from 'notistack';
import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PreConstructionForm from "../PreConstructionForm/preconstructionform";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        padding: theme.spacing(0.5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    root: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(0.25)
    },
    dateField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(-1),
        padding: theme.spacing(0.25)
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
});

class PlanningTool extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            planningtoolData: [],
            selectedRow: {},
            isLoadingTable: true,
            jobplanhour: [],
            crewData: [],
            jobplanhourAPI: false,
            crewAPI: false,
            lookupCrew: [],
            failedjobplanhourAPI: false,
            failedcrewAPI: false,
            OpenDialog: false,
            lookupCells: {},
            ddCells: [],
            selectedCells: 0,
        }
    }
    fetchJobPlanHour() {
        Service.get('/bb/api/jobplanhour/')
            .then(res => {
                if (this._isMounted) {
                    this.setState({ jobplanhour: res.data, jobplanhourAPI: true });
                    if (this.state.ddCells.length > 0)
                        this.getData(this.state.ddCells[0].value);
                    else
                        this.getData("");
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                this.setState({ failedjobplanhourAPI: true });
            });
    }
    fetchCellsbyCrew() {
        Service.get('/bb/api/cellsby/')
            .then(res => {
                if (this._isMounted) {
                    var objAgent = {};
                    for (let i = 0; i < res.data.length; i++) {
                        objAgent[[res.data[i].id]] = res.data[i].crew + " - " + res.data[i].agent;
                        // objAgent[[res.data[i].id]] = res.data[i].cell + " - " + res.data[i].crew + " - " + res.data[i].agent;
                    }
                    var objCells = {};
                    var ddCells1 = [];
                    var cellData = res.data.map(s => s.cell).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => (a > b) ? 1 : -1);
                    for (let i = 0; i < cellData.length; i++) {
                        objCells[[i]] = cellData[i];
                        ddCells1.push({ id: i, value: cellData[i] });
                    }
                    this.setState({ lookupAgent: objAgent, lookupCells: objCells, crewData: res.data, crewAPI: true, ddCells: ddCells1 });
                    if (this.state.ddCells.length > 0)
                        this.getData(this.state.ddCells[0].value);
                    else
                        this.getData("");
                    this.setState({ selectedCells: this.state.ddCells[0].id });
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                this.setState({ failedcrewAPI: true });
                if (this.state.failedjobplanhourAPI && this.state.failedcrewAPI) {
                    this.setState({ isLoadingTable: false });
                }
            });

    }
    getData(cells = null) {
        if (this.state.jobplanhourAPI && this.state.crewAPI && cells != null) {
            const lookupCells = this.state.lookupCells;
            Service.get('/bb/api/planning2/noprecon/?Pre_Con_Site_Visit=3,1,2,0&Cell=' + cells)
                .then(res => {
                    if (this._isMounted) {
                        (res.data).forEach(element => {
                            let jobCode = Headers.lookupJobPlan[element.Job_Plan];
                            let cellIndex = parseInt(Object.keys(lookupCells).find(key => lookupCells[key] === element.Cell));
                            let actualDuration = this.state.jobplanhour.filter(e => e.job_plan === jobCode)[0];
                            element.actualJobDuration = actualDuration !== undefined ? actualDuration.revised : 0;
                            element.Valid_from = element.pre_con_form !== null ? element.Valid_from : null;
                            element.Valid_to = element.pre_con_form !== null ? element.Valid_to : null;
                            element.Cell = isNaN(cellIndex) ? 0 : cellIndex;
                        });
                        this.setState({ planningtoolData: res.data });
                    }
                    this.setState({ isLoadingTable: false });
                })
                .catch(error => {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                    this.setState({ isLoadingTable: false });
                    if (this.state.failedjobplanhourAPI && this.state.failedcrewAPI) {
                        this.setState({ isLoadingTable: false });
                    }
                });
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this.fetchJobPlanHour();
        this.fetchCellsbyCrew();
        this.getData();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleOpenDialog = (e) => {
        this.setState({ OpenDialog: true });
    }
    handleCloseDialog = (e) => {
        this.setState({ OpenDialog: false });
    };
    loadGridData = (ev, ele) => {
        this.setState({ selectedCells: parseInt(ev.target.value) });
        this.setState({ isLoadingTable: true });
        this.getData(ele.key);
    };
    updateCellsbyCrewData = (newData, oldData, resolve) => {
        var crewData = JSON.parse(JSON.stringify(this.state.crewData));
        var cell = this.state.lookupCells[newData.Cell];
        if (newData.cells_by != null) {
            var validation = crewData.filter(e => e.id === parseInt(newData.cells_by) && e.cell === cell);
            if (validation.length === 0) {
                this.props.enqueueSnackbar('Invalid Agent & Crew !', { variant: 'warning' });
                newData.cells_by = null;
                resolve();
                return false;
            }
            if (newData.cells_by === null && newData.start_date === null && newData.finish_date === null) {
                resolve();
                this.props.enqueueSnackbar('Data updated successfully!', { variant: 'success' });
            }
            else {
                Service.put('/bb/api/planning/' + newData.id, { data: { cells_by: newData.cells_by, start_date: newData.start_date, finish_date: newData.finish_date } })
                    .then(res => {
                        this.props.enqueueSnackbar('Data updated successfully!', { variant: 'success' });
                        const dataUpdate = [...this.state.planningtoolData];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        this.setState({ planningtoolData: dataUpdate });
                        resolve();
                    })
                    .catch(error => {
                        this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                        resolve();
                    });
            }
        }
    }

    render() {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <FormControl size="small" variant="outlined" className={useStyles.textField} fullWidth>
                            <InputLabel id="label">Cell</InputLabel>
                            <Select labelId="label" name="selectedCells" value={this.state.selectedCells} onChange={this.loadGridData} label="Cell">
                                {this.state.ddCells.map((cell) => (
                                    <MenuItem key={cell.value} value={cell.id}>
                                        {cell.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <MaterialTable
                            title="Planning Tool"
                            isLoading={this.state.isLoadingTable}
                            columns={[
                                // { title: 'Tab name', field: 'Work_Type', lookup: Headers.lookupWorkTypes, editable: 'never', width: 250, headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, defaultSort: "desc" },
                                { title: 'Work Order #', field: 'Work_Order', editable: 'never', width: 150, headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }, defaultSort: "asc" },
                                // { title: 'Address', field: 'Full_Address', editable: 'never', headerStyle: { minWidth: 1300 }, cellStyle: { minWidth: 1300 } },
                                { title: 'Cell', field: 'Cell', editable: 'never', width: 150, lookup: this.state.lookupCells },
                                { title: 'Crew & Agent', field: 'cells_by', lookup: this.state.lookupAgent, width: 350, headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 } },
                                { title: 'Job Plan', field: 'Job_Plan', lookup: Headers.lookupJobPlan, editable: 'never', width: 150, headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 } },
                                { title: 'Actual job plan duration', field: 'actualJobDuration', width: 300, headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 } },
                                { title: 'Start Date & Time', field: 'start_date', type: "datetime", width: 250, headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 } },
                                { title: 'Finish Date & Time', field: 'finish_date', type: "datetime", width: 250, headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }, editable: 'never' },
                                { title: 'Appointment Start', field: 'Appointment_Start', type: "date", width: 200, editable: 'never', headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 } },
                                { title: 'Appointment End', field: 'Appointment_End', type: "date", editable: 'never', width: 200, headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 } },
                                { title: 'WO Status', field: 'WO_Status', lookup: Headers.lookupWOStatus, editable: 'never', width: 200, headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 } },
                                { title: 'Project Number', field: 'Project_WO_Code', editable: 'never', width: 200, headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 } },
                                { title: 'License Start', field: 'Valid_from', type: "date", editable: 'never', width: 150, headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 } },
                                { title: 'License Complete', field: 'Valid_to', type: "date", editable: 'never', width: 200, headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 } },
                                { title: 'District', field: 'District', editable: 'never', width: 350, headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 } }
                            ]}
                            data={this.state.planningtoolData}
                            options={{
                                filtering: true,
                                pageSize: 20,
                                // doubleHorizontalScroll: true,
                                // headerStyle: { position: 'sticky', top: 0 },
                                // maxBodyHeight: Math.round(window.screen.height - window.screen.height * 0.45),
                                fixedColumns: {
                                    left: 3,
                                    right: 1
                                },
                                actionsColumnIndex: -1,
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            if (newData.start_date != null) {
                                                var startdt = new Date(newData.start_date);
                                                var actualJobDuration = parseInt(newData.actualJobDuration);
                                                var finishdt = startdt.setHours(startdt.getHours() + actualJobDuration);
                                                newData.finish_date = new Date(finishdt);
                                            }
                                            else {
                                                newData.finish_date = null;
                                            }
                                            this.updateCellsbyCrewData(newData, oldData, resolve);
                                        }, 1000);
                                    }),
                            }}
                            actions={[
                                {
                                    icon: AssignmentTurnedInIcon,
                                    tooltip: 'Status',
                                    onClick: (event, rowData) => {
                                        this.setState({ OpenDialog: true, selectedRow: rowData });
                                    }
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
                <Dialog fullScreen
                    open={this.state.OpenDialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Pre-Construction Form"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText component="div">
                            <AppBar position="fixed">
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.handleCloseDialog} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" component="span">
                                        Pre-Construction Form
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <PreConstructionForm selectedRow={this.state.selectedRow} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withStyles(useStyles)(withSnackbar(PlanningTool));