import React, { Component } from 'react'
import { withStyles, Grid } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PreConstructionForm from "../PreConstructionForm/preconstructionform";
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import GetAllocationCentresData from './GetAllocationCentresData';
import { CircleToBlockLoading } from 'react-loadingg';

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        padding: theme.spacing(0.5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    root: {
        flexGrow: 1,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(0.25)
    },
    dateField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(-1),
        padding: theme.spacing(0.25)
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
});

class AllocationCentres extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            allocationCentresType: this.props.type,
            allocationCentresTitle: this.props.type === "ReinstatingAgent" ? "Reinstating Agent Allocation Centres" : "Reinstating Crew Allocation Centres",
            dropDownLabel: this.props.type === "ReinstatingAgent" ? "Reinstating Agent" : "Reinstating Crew",
            isHideCols: this.props.type === "ReinstatingAgent" ? false : true,
            ddData: [],
            selectedRow: {},
            OpenDialog: false,
            selectedValue: 0,
            isLoadMainTable: true,
            isLoaddropDownValue: true
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this.fetchDropDownValues(this.state.allocationCentresType);
    }
    fetchDropDownValues(type) {
        let url = "/bb/api/";
        if (type === "ReinstatingAgent") {
            url = url + "reinstating/agent/";
        } else {
            url = url + "reinstating/crew/";
        }
        Service.get(url)
            .then(res => {
                if (this._isMounted) {
                    var ddData = [];
                    for (let i = 0; i < res.data.length; i++) {
                        ddData.push({ id: res.data[i].id, value: res.data[i].name });
                    }
                    this.setState({ ddData: ddData, selectedValue: ddData[0].id, isLoadMainTable: false, isLoaddropDownValue: false });
                }
            }).catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleChildEvent = (OpenDialog, selectedRow) => {
        this.setState({ OpenDialog: true, selectedRow: selectedRow });
    }
    handleCloseDialog = (e) => {
        this.setState({ OpenDialog: false });
    };
    loadGridData = (ev, ele) => {
        this.setState({ selectedValue: parseInt(ev.target.value), isLoadMainTable: true }, () => {
            this.setState({ isLoadMainTable: false });
        });
    };

    render() {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <FormControl size="small" variant="outlined" className={useStyles.textField} fullWidth>
                            <InputLabel id="label">{this.state.dropDownLabel}</InputLabel>
                            <Select labelId="label" name="selectedValue" value={this.state.selectedValue} onChange={this.loadGridData} label={this.state.dropDownLabel}>
                                {
                                    (this.state.isLoaddropDownValue) ?
                                        (
                                            <MenuItem key={"Select " + this.state.dropDownLabel} value={0}>
                                                {"Select"}
                                            </MenuItem>
                                        )
                                        :
                                        (
                                            this.state.ddData.map((data) => (
                                                <MenuItem key={data.value} value={data.id}>
                                                    {data.value}
                                                </MenuItem>
                                            ))
                                        )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {(this.state.isLoadMainTable) ?
                            (<CircleToBlockLoading color='#005D99' />)
                            : (<GetAllocationCentresData title={this.state.allocationCentresTitle} isHideCols={this.state.isHideCols} type={this.state.allocationCentresType} handleChildEvent={this.handleChildEvent} value={this.state.selectedValue} />)
                        }
                    </Grid>
                </Grid>
                <Dialog fullScreen
                    open={this.state.OpenDialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Pre-Construction Form"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText component="div">
                            <AppBar position="fixed">
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.handleCloseDialog} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" component="span">
                                        Pre-Construction Form
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <PreConstructionForm selectedRow={this.state.selectedRow} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}
export default withStyles(useStyles)(withSnackbar(AllocationCentres));