import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { DropzoneDialog } from 'material-ui-dropzone';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx';
// import '../CSS/styles.css';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Toolbar from '@material-ui/core/Toolbar';
import Headers from '../ContractorPlanning/Headers';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import { Grid, TextField } from '@material-ui/core';
import Service from "../Networking/networkutils";
import { CircleToBlockLoading } from 'react-loadingg';
import PreConstructionForm from "../PreConstructionForm/preconstructionform";
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core';
import ContractorPlanningTable from './ContractorPlanningTable';
import LoopIcon from '@material-ui/icons/Loop';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';

const classes = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: '30ch',
    padding: theme.spacing(0.5)
  },
  margin: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1)
  },
  title: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography variant="h6" component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function GetSplitedData(data, chunkSize) {
  return Array(Math.ceil(data.length / chunkSize)).fill().map(function (_, i) {
    return data.slice(i * chunkSize, i * chunkSize + chunkSize);
  });
}
function parseReadableData(ws, workSheetName) {
  const data = XLSX.utils.sheet_to_json(ws, { blankrows: false, defval: null });
  const workType = Headers.getLookUpValue('Work_Type', workSheetName);
  for (var i = 0; i < data.length; i++) {
    const rowData = data[i];
    rowData['Work_Type'] = workType;
    Object.keys(rowData).map((key, index) => {
      return rowData[key] = Headers.getDatabyType(key, rowData[key]);
    });
  }
  return data;
}
function getUpdatedData(oldData, newData, clsObj) {
  // var diff = Object.keys(newData).filter(k => newData[k] !== oldData[k]);
  // var same = Object.keys(newData).filter(k => newData[k] === oldData[k]);
  const oldInsertWorkOrderNos = oldData.filter(row => row.status !== 3).map(s => s.Work_Order);
  const newInsertWorkOrderNos = newData.map(s => s.Work_Order);
  const curTabremovedWorkOrderNos = oldInsertWorkOrderNos.filter(element => !newInsertWorkOrderNos.includes(element));
  var preTabsremovedWorkOrderNos = clsObj.state.removedWorkOrderNos;
  var removedWorkOrderNos = curTabremovedWorkOrderNos.concat(preTabsremovedWorkOrderNos);
  clsObj.setState({ removedWorkOrderNos: removedWorkOrderNos });
  const deletedWorkOrders = oldData.filter(row => row.status !== 3).filter(element => !newInsertWorkOrderNos.includes(element.Work_Order)).map(obj => obj = { ...obj, Deletedrow: true, is_completed: 2, status: 3 });
  if (newData.length > 0) {
    for (var i = 0; i < newData.length; i++) {
      const workOrderNo = newData[i].Work_Order;
      const existData = oldData.filter(d => d.Work_Order === workOrderNo)[0];
      if (existData !== null && existData !== undefined) {
        const currentData = newData[i];
        const cellStyle = Object.keys(currentData).filter(k => currentData[k] !== existData[k]);
        const cellStyles = [...cellStyle];
        if (cellStyle.length > 0) {
          for (var j = 0; j < cellStyle.length; j++) {
            var prop = cellStyle[j];
            if (Headers.dateTypes.includes(prop)) {
              var currentDate = new Date(currentData[prop]);
              var existDate = new Date(existData[prop]);
              if (currentDate.getTime() === existDate.getTime()) {
                const index = cellStyles.indexOf(prop);
                if (index > -1) {
                  cellStyles.splice(index, 1);
                }
              }
            }
          }
        }
        currentData["cellStyle"] = cellStyles;
        currentData["id"] = existData["id"];
        newData[i]["is_completed"] = 1;
        newData[i]["status"] = 2;
      }
      else {
        newData[i]["rowStyle"] = true;
        newData[i]["is_completed"] = 1;
        newData[i]["status"] = 1;
      }
    }
  }
  const updateData = newData.filter(row => (row.hasOwnProperty('cellStyle') && row['cellStyle'].length > 0) || row.hasOwnProperty('rowStyle'));
  return updateData.concat(deletedWorkOrders);
}
class ContractorPlanning extends Component {
  constructor(props) {
    super(props);
    this.updateTab = this.updateTab.bind(this);
    this.state = {
      Value: 0,
      tempValue: 0,
      workStatus: false,
      ServicesAppointmentsColumns: Headers.servicesAppointmentsColumns,
      ServicesAppointmentsColumns1: Headers.servicesAppointmentsColumns1,
      ServicesAppointmentsData1: [],
      MaintenanceAppointmentsColumns: Headers.maintenanceAppointmentsColumns,
      MaintenanceAppointmentsColumns1: Headers.maintenanceAppointmentsColumns1,
      MaintenanceAppointmentsData1: [],
      MaintCTSRCTSPREVColumns: Headers.maintCTSRCTSPREVColumns,
      MaintCTSRCTSPREVColumns1: Headers.maintCTSRCTSPREVColumns1,
      MaintCTSRCTSPREVData1: [],
      MaintCstContributionWorksColumns: Headers.maintCstContributionWorksColumns,
      MaintCstContributionWorksColumns1: Headers.maintCstContributionWorksColumns1,
      MaintCstContributionWorksData1: [],
      OpenDialog: false,
      open: false,
      openPreConstructionForm: false,
      selectedRow: {},
      frmTitle: "",
      programWorksData1: [],
      maintOtherData1: [],
      constructionProjectsData1: [],
      dRsData1: [],
      civilCrewData1: [],
      isLoadMainTable: true,
      allData: [],
      removedWorkOrderNos: [],
      tableTpyeChange: false,
      searchWorkOrderNo: null
    };
  }
  updateTab(tab) {
    this.setState({
      Value: tab,
      searchWorkOrderNo: null
    });
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose2 = (data) => {
    this.setState({ open: false });
    this.setState({ removedWorkOrderNos: [] });
  };
  openPreConstructionForm = (data) => {
    this.setState({ openPreConstructionForm: true });
  };
  closePreConstructionForm = (data) => {
    this.setState({ openPreConstructionForm: false });
  };
  loadPreConstructionForm(event, selectedRow) {
    this.setState({ openPreConstructionForm: true });
    this.setState({ selectedRow: selectedRow });
  }
  InsertData(data, index, resolve) {
    if (data.length > index) {
      Service.post('/bb/api/planning/', { data: data[index] })
        .then(res => {
          this.InsertData(data, index + 1, resolve);
        })
        .catch(error => {
          this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
          this.InsertData(data, index + 1);
          console.log(error);
        });
    }
    else {
      resolve();
      return true;
    }
  }

  UpdateData(data, index, resolve) {
    if (data.length > index) {
      Service.patch('/bb/api/planning/bulk_update/', { data: data[index] })
        .then(res => {
          this.UpdateData(data, index + 1, resolve);
        })
        .catch(error => {
          console.log(error);
          this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
          this.UpdateData(data, index + 1);
        });
    }
    else {
      resolve();
      return true;
    }
  }

  setMainGridData = (data) => {

    this.setState({ isLoadMainTable: true });

    const combinedData = [...this.state.ServicesAppointmentsData1, ...this.state.MaintenanceAppointmentsData1, ...this.state.MaintCTSRCTSPREVData1, ...this.state.MaintCstContributionWorksData1, ...this.state.programWorksData1, ...this.state.maintOtherData1, ...this.state.constructionProjectsData1, ...this.state.dRsData1, ...this.state.civilCrewData1];

    if (combinedData.length > 0) {

      const insertData = combinedData.filter(row => row.hasOwnProperty('rowStyle'));
      const updateData = combinedData.filter(row => row.hasOwnProperty('cellStyle') && row['cellStyle'].length > 0);
      const deletedData = combinedData.filter(row => row.hasOwnProperty('Deletedrow'));

      const existingWorkOrderNos = this.state.allData.map(s => s.Work_Order);
      const newInsertWorkOrderNos = insertData.map(s => s.Work_Order);

      const isWorkOrderNoExists = newInsertWorkOrderNos.filter(element => existingWorkOrderNos.includes(element));

      if (isWorkOrderNoExists.length > 0) {
        this.props.enqueueSnackbar('Operation failed, because the same copy of the Work-Order No(s) already exists. The Work-Order No(s) : ' + isWorkOrderNoExists.join(','), { variant: 'error' });
        this.setState({ isLoadMainTable: false });
        return false;
      }
      var insertDataAPI = new Promise((resolve, reject) => {
        if (insertData.length > 0) {
          var result = GetSplitedData(insertData, 500);
          this.InsertData(result, 0, resolve);
        } else {
          resolve();
        }
      });

      var updateDataAPI = new Promise((resolve, reject) => {
        if (updateData.length > 0) {
          var resultUpdate = GetSplitedData(updateData, 500);
          this.UpdateData(resultUpdate, 0, resolve);
        } else {
          resolve();
        }
      });

      var deleteDataAPI = new Promise((resolve, reject) => {
        if (deletedData.length > 0) {
          var deteteData = GetSplitedData(deletedData, 500);
          this.UpdateData(deteteData, 0, resolve);
        } else {
          resolve();
        }
      });

      Promise.all([insertDataAPI, updateDataAPI, deleteDataAPI])
        .then(() => {
          this.props.enqueueSnackbar('Data updated successfully!', { variant: 'success' });
          this.setState({ isLoadMainTable: false, open: false });
          this.getData();
        }).catch((error) => {
          console.log(error);
          this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
          this.setState({ isLoadMainTable: false, open: false });
        });
    }
    else if (this.state.removedWorkOrderNos.length > 0) {
      this.props.enqueueSnackbar('Data updated successfully!', { variant: 'success' });
      this.setState({ isLoadMainTable: false, open: false });
      this.getData();
    }
    else {
      this.props.enqueueSnackbar('Nothing to update!', { variant: 'info' });
      this.setState({ isLoadMainTable: false });
    }
  };
  getData() {
    this.setState({ Value: 0, tableTpyeChange: true }, () => { this.setState({ tableTpyeChange: false }) });
  }
  handleChange = (event, newValue) => {
    this.setState({ Value: newValue, tableTpyeChange: true }, () => { this.setState({ tableTpyeChange: false }) });
  }
  handleChange2 = (event, newValue) => {
    this.setState({ tempValue: newValue });
  }
  statusChanged = (event) => {
    var value = event.target.checked;
    this.setState({ workStatus: value, tableTpyeChange: true }, () => { this.setState({ tableTpyeChange: false }) });
  }
  handleOpen = () => {
    this.setState({ OpenDialog: true });
  }
  handleClose() {
    this.setState({ OpenDialog: false });
  }
  resetGrid = (event) => {
    document.getElementsByName("searchWorkOrderNo")[0].value = "";
    this.setState({ tableTpyeChange: true, searchWorkOrderNo: null }, () => { this.setState({ tableTpyeChange: false }) });
  }
  searchWorkOrder = (event) => {
    const workOrderNo = document.getElementsByName("searchWorkOrderNo")[0].value;
    if (workOrderNo && !isNaN(parseInt(workOrderNo))) {
      this.setState({ tableTpyeChange: true }, () => {
        this.setState({
          tableTpyeChange: false,
          searchWorkOrderNo: parseInt(workOrderNo)
        });
      });
    }
    else {
      this.setState({
        searchWorkOrderNo: null
      });
      this.props.enqueueSnackbar('Enter the work order number to search!', { variant: 'info' });
    }
  }
  handleSave(files) {
    this.setState({ OpenDialog: false, open: true, isLoadMainTable: true, removedWorkOrderNos: [] });
    /* Get Excel Data */
    var file = files[0];
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {

      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array", bookVBA: true, cellDates: true, cellText: false });

      const newXLData = [];
      newXLData.push(parseReadableData(wb.Sheets[wb.SheetNames[0]], "Services Appointments"));
      newXLData.push(parseReadableData(wb.Sheets[wb.SheetNames[1]], "Maintenance Appointments"));
      newXLData.push(parseReadableData(wb.Sheets[wb.SheetNames[2]], "Maint CTSR & CTSPREV"));
      newXLData.push(parseReadableData(wb.Sheets[wb.SheetNames[3]], "Maint Cst Contribution Works"));
      newXLData.push(parseReadableData(wb.Sheets[wb.SheetNames[4]], "Program Works"));
      newXLData.push(parseReadableData(wb.Sheets[wb.SheetNames[5]], "Maint Other"));
      newXLData.push(parseReadableData(wb.Sheets[wb.SheetNames[6]], "Construction Projects"));
      newXLData.push(parseReadableData(wb.Sheets[wb.SheetNames[7]], "DRs"));
      newXLData.push(parseReadableData(wb.Sheets[wb.SheetNames[8]], "Civil Crew"));

      var oldServicesAppointmentsData = [];
      var oldMaintenanceAppointmentsData = [];
      var oldMaintCTSRCTSPREVData = [];
      var oldMaintCstContributionWorksData = [];
      var oldProgramWorksData = [];
      var oldMaintOtherData = [];
      var oldConstructionProjectsData = [];
      var oldDRsData = [];
      var oldCivilCrewData = [];

      let OldData = [];
      let tabs = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      Promise.all(
        tabs.map(id => {
          return new Promise((resolve) => {
            Service.get('/bb/api/planning2/noprecon/?Work_Type=' + id)
              .then(response => {
                return new Promise(() => {
                  OldData.push(response.data);
                  resolve();
                })
              }).catch((error) => {
                console.log(error);
                resolve();
              });
          })
        })).then(() => {
          var data = [...OldData[0], ...OldData[1], ...OldData[2], ...OldData[3], ...OldData[4], ...OldData[5], ...OldData[6], ...OldData[7], ...OldData[8]];
          oldServicesAppointmentsData = data.filter(row => row.Work_Type === 1);
          oldMaintenanceAppointmentsData = data.filter(row => row.Work_Type === 2);
          oldMaintCTSRCTSPREVData = data.filter(row => row.Work_Type === 3);
          oldMaintCstContributionWorksData = data.filter(row => row.Work_Type === 4);
          oldProgramWorksData = data.filter(row => row.Work_Type === 5);
          oldMaintOtherData = data.filter(row => row.Work_Type === 6);
          oldConstructionProjectsData = data.filter(row => row.Work_Type === 7);
          oldDRsData = data.filter(row => row.Work_Type === 8);
          oldCivilCrewData = data.filter(row => row.Work_Type === 9);
          var updatedServicesAppointmentsData = getUpdatedData(oldServicesAppointmentsData, newXLData[0], this);
          var updatedMaintenanceAppointmentsData = getUpdatedData(oldMaintenanceAppointmentsData, newXLData[1], this);
          var updatedMaintCTSRCTSPREVData = getUpdatedData(oldMaintCTSRCTSPREVData, newXLData[2], this);
          var updatedMaintCstContributionWorksData = getUpdatedData(oldMaintCstContributionWorksData, newXLData[3], this);
          var updatedProgramWorksData = getUpdatedData(oldProgramWorksData, newXLData[4], this);
          var updatedMaintOtherData = getUpdatedData(oldMaintOtherData, newXLData[5], this);
          var updatedConstructionProjectsData = getUpdatedData(oldConstructionProjectsData, newXLData[6], this);
          var updatedDRsData = getUpdatedData(oldDRsData, newXLData[7], this);
          var updatedCivilCrewData = getUpdatedData(oldCivilCrewData, newXLData[8], this);
          if (updatedServicesAppointmentsData.length === 0 && updatedMaintenanceAppointmentsData.length === 0 && updatedMaintCTSRCTSPREVData.length === 0 && updatedMaintCstContributionWorksData.length === 0 && updatedProgramWorksData.length === 0 && updatedMaintOtherData.length === 0 && updatedConstructionProjectsData.length === 0 && updatedDRsData.length === 0 && updatedCivilCrewData.length === 0 && this.state.removedWorkOrderNos.length === 0) {
            this.props.enqueueSnackbar('Nothing to update !', { variant: 'info' });
            this.setState({
              ServicesAppointmentsData1: [], MaintenanceAppointmentsData1: [], MaintCTSRCTSPREVData1: [], MaintCstContributionWorksData1: [], programWorksData1: [], maintOtherData1: [], constructionProjectsData1: [], dRsData1: [], civilCrewData1: [], isLoadMainTable: false, allData: data
            });
          }
          else {
            this.setState({
              ServicesAppointmentsData1: updatedServicesAppointmentsData, MaintenanceAppointmentsData1: updatedMaintenanceAppointmentsData, MaintCTSRCTSPREVData1: updatedMaintCTSRCTSPREVData, MaintCstContributionWorksData1: updatedMaintCstContributionWorksData, programWorksData1: updatedProgramWorksData, maintOtherData1: updatedMaintOtherData, constructionProjectsData1: updatedConstructionProjectsData, dRsData1: updatedDRsData, civilCrewData1: updatedCivilCrewData, isLoadMainTable: false, allData: data
            });
          }
        }).catch((error) => {
          console.log(error);
          this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
          this.setState({
            ServicesAppointmentsData1: [], MaintenanceAppointmentsData1: [], MaintCTSRCTSPREVData1: [], MaintCstContributionWorksData1: [], programWorksData1: [], maintOtherData1: [], constructionProjectsData1: [], dRsData1: [], civilCrewData1: [], isLoadMainTable: false, allData: [],
          });
        });
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  }
  render() {
    return (
      <div className="root">
        <Grid container alignItems="center" >
          <Grid item>
            <Button variant="contained" color="primary" onClick={this.handleOpen}>Upload Excel File</Button>
            <p>   </p>
          </Grid>
          <Grid item >&nbsp;&nbsp;&nbsp;In Progress <p>   </p></Grid>
          <Grid item >
            <Switch value={this.state.workStatus} onChange={this.statusChanged} name="workStatus" />
            <p>   </p>
          </Grid>
          <Grid item>Completed <p>   </p></Grid>
          <Grid item >&nbsp;&nbsp;&nbsp; <p>   </p></Grid>
          <Grid item>
            <TextField type="number" size="small" style={{ width: "100%" }} name="searchWorkOrderNo" label="Search Work Order No" variant="outlined" />
            <p>   </p>
          </Grid>
          <Grid item>
            <Tooltip title="Search Work Order No" aria-label="Search Work Order No">
              <IconButton onClick={this.searchWorkOrder.bind(this)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <p>   </p>
          </Grid>
          <Grid item>
            <Tooltip title="Reset" aria-label="Reset">
              <IconButton onClick={this.resetGrid}>
                <LoopIcon />
              </IconButton>
            </Tooltip>
            <p>   </p>
          </Grid>
        </Grid>
        <DropzoneDialog
          open={this.state.OpenDialog}
          maxFileSize={20000000}
          onSave={this.handleSave.bind(this)}
          acceptedFiles={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
          showPreviews={true}
          onClose={this.handleClose.bind(this)}
        />
        <AppBar position="static" color="inherit">
          <Tabs value={this.state.Value} onChange={this.handleChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
            <Tab label="Services Appointments" {...a11yProps(0)} />
            <Tab label="Maintenance Appointments" {...a11yProps(1)} />
            <Tab label="Maint CTSR & CTSPREV" {...a11yProps(2)} />
            <Tab label="Maint Cst Contribution Works" {...a11yProps(3)} />
            <Tab label="Program Works" {...a11yProps(4)} />
            <Tab label="Maint Other" {...a11yProps(5)} />
            <Tab label="Construction Projects" {...a11yProps(6)} />
            <Tab label="DRs" {...a11yProps(7)} />
            <Tab label="Civil Crew" {...a11yProps(8)} />
          </Tabs>
        </AppBar>
        {
          (this.state.tableTpyeChange) ? (
            null
          ) : (
              <div>
                {
                  (this.state.Value === 0) ? (
                    <ContractorPlanningTable title="Services Appointments" columns={this.state.ServicesAppointmentsColumns} WorkType={1} IsCompleted={this.state.workStatus ? "2" : "0,1"} loadPreConstructionForm={true} stateVariable={this} />
                  ) : (this.state.Value === 1) ? (
                    <ContractorPlanningTable title="Maintenance Appointments" columns={this.state.MaintenanceAppointmentsColumns} WorkType={2} IsCompleted={this.state.workStatus ? "2" : "0,1"} loadPreConstructionForm={true} stateVariable={this} />
                  ) : (this.state.Value === 2) ? (
                    <ContractorPlanningTable title="Maint CTSR & CTSPREV" columns={this.state.MaintCTSRCTSPREVColumns} WorkType={3} IsCompleted={this.state.workStatus ? "2" : "0,1"} loadPreConstructionForm={true} stateVariable={this} />
                  ) : (this.state.Value === 3) ? (
                    <ContractorPlanningTable title="Maint Cst Contribution Works" columns={this.state.MaintCstContributionWorksColumns} WorkType={4} IsCompleted={this.state.workStatus ? "2" : "0,1"} loadPreConstructionForm={true} stateVariable={this} />
                  ) : (this.state.Value === 4) ? (
                    <ContractorPlanningTable title="Program Works" columns={Headers.programWorksColumns} WorkType={5} IsCompleted={this.state.workStatus ? "2" : "0,1"} loadPreConstructionForm={true} stateVariable={this} />
                  ) : (this.state.Value === 5) ? (
                    <ContractorPlanningTable title="Maint Other" columns={Headers.maintOtherColumns} WorkType={6} IsCompleted={this.state.workStatus ? "2" : "0,1"} loadPreConstructionForm={true} stateVariable={this} />
                  ) : (this.state.Value === 6) ? (
                    <ContractorPlanningTable title="Construction Projects" columns={Headers.constructionProjectsColumns} WorkType={7} IsCompleted={this.state.workStatus ? "2" : "0,1"} loadPreConstructionForm={true} stateVariable={this} />
                  ) : (this.state.Value === 7) ? (
                    <ContractorPlanningTable title="DRs" columns={Headers.dRsColumns} WorkType={8} IsCompleted={this.state.workStatus ? "2" : "0,1"} loadPreConstructionForm={true} stateVariable={this} />
                  ) : (this.state.Value === 8) ? (
                    <ContractorPlanningTable title="Civil Crew" columns={Headers.civilCrewColumns} WorkType={9} IsCompleted={this.state.workStatus ? "2" : "0,1"} loadPreConstructionForm={true} stateVariable={this} />
                  ) : (null)
                }

                {/* <ContractorPlanningTable title="Services Appointments" columns={this.state.ServicesAppointmentsColumns} WorkType={1} IsCompleted={this.state.isCompleted} loadPreConstructionForm={true} stateVariable={this} /> */}

                {/* <LoadDataTable title="Maintenance Appointments" columns={this.state.MaintenanceAppointmentsColumns} data={this.state.MaintenanceAppointmentsData} loadPreConstructionForm={true} stateVariable={this} /> */}
                {/* <LoadDataTable title="Maint CTSR & CTSPREV" columns={this.state.MaintCTSRCTSPREVColumns} data={this.state.MaintCTSRCTSPREVData} stateVariable={this} loadPreConstructionForm={true} /> */}

                {/* <LoadDataTable title="Maint Cst Contribution Works" columns={this.state.MaintCstContributionWorksColumns} loadPreConstructionForm={true} data={this.state.MaintCstContributionWorksData} stateVariable={this} /> */}
                {/* <LoadDataTable title="Program Works" columns={Headers.programWorksColumns} loadPreConstructionForm={true} data={this.state.programWorksData} stateVariable={this} /> */}
                {/* <LoadDataTable title="Maint Other" columns={Headers.maintOtherColumns} loadPreConstructionForm={true} data={this.state.maintOtherData} stateVariable={this} /> */}
                {/* <LoadDataTable title="Construction Projects" columns={Headers.constructionProjectsColumns} loadPreConstructionForm={true} data={this.state.constructionProjectsData} stateVariable={this} /> */}
                {/* <LoadDataTable title="DRs" columns={Headers.dRsColumns} loadPreConstructionForm={true} data={this.state.dRsData} stateVariable={this} /> */}
                {/* <LoadDataTable title="Civil Crew" columns={Headers.civilCrewColumns} loadPreConstructionForm={true} data={this.state.civilCrewData} stateVariable={this} /> */}
              </div>

            )
        }

        <Dialog fullScreen open={this.state.open} onClose={this.handleClose2} TransitionComponent={Transition}>
          <DialogContent>
            <DialogContentText component="div">
              <AppBar position="relative">
                <Toolbar>
                  <Typography variant="h6" component="span">
                    Are you sure ! Do you want to import this data?
                  </Typography>
                </Toolbar>
              </AppBar>
              {(this.state.isLoadMainTable) ?
                (<CircleToBlockLoading color='#005D99' />)
                : (
                  <div>
                    <Tabs value={this.state.tempValue} onChange={this.handleChange2} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
                      <Tab label="Services Appointments" {...a11yProps(0)} />
                      <Tab label="Maintenance Appointments" {...a11yProps(1)} />
                      <Tab label="Maint CTSR & CTSPREV" {...a11yProps(2)} />
                      <Tab label="Maint Cst Contribution Works" {...a11yProps(3)} />
                      <Tab label="Program Works" {...a11yProps(4)} />
                      <Tab label="Maint Other" {...a11yProps(5)} />
                      <Tab label="Construction Projects" {...a11yProps(6)} />
                      <Tab label="DRs" {...a11yProps(7)} />
                      <Tab label="Civil Crew" {...a11yProps(8)} />
                    </Tabs>
                    <TabPanel value={this.state.tempValue} index={0}>
                      <LoadDataTable title="Services Appointments" columns={this.state.ServicesAppointmentsColumns1} data={this.state.ServicesAppointmentsData1} stateVariable={this} />
                    </TabPanel>
                    <TabPanel value={this.state.tempValue} index={1}>
                      <LoadDataTable title="Maintenance Appointments" columns={this.state.MaintenanceAppointmentsColumns1} data={this.state.MaintenanceAppointmentsData1} stateVariable={this} />
                    </TabPanel>
                    <TabPanel value={this.state.tempValue} index={2}>
                      <LoadDataTable title="Maint CTSR & CTSPREV" columns={this.state.MaintCTSRCTSPREVColumns1} data={this.state.MaintCTSRCTSPREVData1} stateVariable={this} />
                    </TabPanel>
                    <TabPanel value={this.state.tempValue} index={3}>
                      <LoadDataTable title="Maint Cst Contribution Works" columns={this.state.MaintCstContributionWorksColumns1} data={this.state.MaintCstContributionWorksData1} stateVariable={this} />
                    </TabPanel>
                    <TabPanel value={this.state.tempValue} index={4}>
                      <LoadDataTable title="Program Works" columns={Headers.programWorksColumns1} data={this.state.programWorksData1} stateVariable={this} />
                    </TabPanel>
                    <TabPanel value={this.state.tempValue} index={5}>
                      <LoadDataTable title="Maint Other" columns={Headers.maintOtherColumns1} data={this.state.maintOtherData1} stateVariable={this} />
                    </TabPanel>
                    <TabPanel value={this.state.tempValue} index={6}>
                      <LoadDataTable title="Construction Projects" columns={Headers.constructionProjectsColumns1} data={this.state.constructionProjectsData1} stateVariable={this} />
                    </TabPanel>
                    <TabPanel value={this.state.tempValue} index={7}>
                      <LoadDataTable title="DRs" columns={Headers.dRsColumns1} data={this.state.dRsData1} stateVariable={this} />
                    </TabPanel>
                    <TabPanel value={this.state.tempValue} index={8}>
                      <LoadDataTable title="Civil Crew" columns={Headers.civilCrewColumns1} data={this.state.civilCrewData1} stateVariable={this} />
                    </TabPanel>
                  </div>
                )
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.handleClose2.bind(this)} color="primary">
              Disagree
          </Button>
            <Button onClick={this.setMainGridData.bind(this)} color="primary" autoFocus>
              Agree
          </Button>
          </DialogActions>
        </Dialog>
        <Dialog fullScreen open={this.state.openPreConstructionForm} onClose={this.closePreConstructionForm} TransitionComponent={Transition}>
          <DialogContent>
            <DialogContentText component="div">
              <AppBar position="fixed">
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={this.closePreConstructionForm} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" component="span">
                    Pre-Construction Form
                  </Typography>
                </Toolbar>
              </AppBar>
              <PreConstructionForm selectedRow={this.state.selectedRow} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.closePreConstructionForm.bind(this)} color="primary">
              Close
             </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
class LoadDataTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentDidMount() {

  }
  render() {
    return (
      <MaterialTable
        title={this.props.title}
        columns={this.props.columns}
        isLoading={this.props.stateVariable.state.isLoadMainTable}
        data={loadData(this.props.data, this.props.stateVariable.state.workStatus, this.props.loadPreConstructionForm, this.props.stateVariable.state.removedWorkOrderNos)}
        onRowClick={((evt, selectedRow) => (this.props.loadPreConstructionForm) ? this.props.stateVariable.loadPreConstructionForm(evt, selectedRow) : evt.preventDefault())}
        options={{
          pageSize: 20,
          search: true,
          headerStyle: { position: 'sticky', top: 0 },
          maxBodyHeight: Math.round(window.screen.height - window.screen.height * 0.45),
          filtering: true,
          sorting: true,
          doubleHorizontalScroll: true,
          rowStyle: rowData => ({
            color: getColor(rowData),
            backgroundColor: (rowData.rowStyle) ? '#FFF' : '',
          })
        }}
      />
    );
  }
}

function getColor(rowData) {
  if (rowData.rowStyle || rowData.status === 1) {
    return "#008000";
  }
  else if (rowData.Deletedrow || rowData.status === 3) {
    return "#FF0000";
  }
  else if (rowData.status === 2 || rowData.status === 0) {
    return "#FFA500";
  }
  else {
    return "";
  }
}
function loadData(data, status, isMainTable, removedWorkOrderNos) {
  if (isMainTable && (data != null)) {
    if (status) {
      // return (removedWorkOrderNos.length > 0) ? data.filter(row => removedWorkOrderNos.includes(row.Work_Order)) : [];
      return data.filter(row => (row.is_completed === undefined ? '' : parseInt(row.is_completed)) === (2));
    }
    else {
      return data.filter(row => (row.is_completed === undefined ? '' : parseInt(row.is_completed)) !== (2));
      // return (removedWorkOrderNos.length > 0) ? data.filter(row => !removedWorkOrderNos.includes(row.Work_Order)) : data;
    }
  }
  else {
    return data;
  }
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default withStyles(classes)(withSnackbar(ContractorPlanning));