import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { FormControl } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class FilterComponent extends React.Component {


    onFilterValueChanged = event => {
        this.props.ShowLoader();
        this.props.SetSelectedValue(event.target.value);
        setTimeout(() => {
            const selectedCellsValue = this.props.SelectedCellValue();
            const selectedAgentsValue = this.props.SelectedAgentValue();
            const selectedCrewsValue = this.props.SelectedCrewValue();
            if (selectedCellsValue.length <= 0 && selectedAgentsValue.length <= 0 && selectedCrewsValue.length <= 0) {
                this.props.filteredData(this.props.planningtoolData);
                this.props.SetSelectedValue([]);
                this.props.HideLoader();
            }
            else {
                var newfilteredData = Object.assign(this.props.planningtoolData.filter(s => s.cells_by), {});
                if (selectedCellsValue.length > 0) {
                    var id = this.props.AllCells.filter(row => selectedCellsValue.includes(row.name)).map(s => s.value);
                    newfilteredData = newfilteredData.filter(row => id.includes(row.cells_by));
                }
                if (selectedAgentsValue.length > 0) {
                    var agentid = this.props.AllAgents.filter(row => selectedAgentsValue.includes(row.name)).map(s => s.value);
                    newfilteredData = newfilteredData.filter(row => agentid.includes(row.cells_by));
                }
                if (selectedCrewsValue.length > 0) {
                    var crewsId = this.props.AllCrews.filter(row => selectedCrewsValue.includes(row.name)).map(s => s.value);
                    newfilteredData = newfilteredData.filter(row => crewsId.includes(row.cells_by));
                }
                this.props.filteredData(newfilteredData);
                this.props.HideLoader();
            }
        }, 1000);
    };

    render() {
        return (
            <div>
                <FormControl>
                    <Select multiple value={this.props.selectedValue} onChange={this.onFilterValueChanged} input={<Input />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}>
                        {this.props.UniqueValues.map((data) => (
                            <MenuItem key={data} value={data}>
                                <Checkbox checked={this.props.selectedValue.indexOf(data) > -1} />
                                <ListItemText primary={data} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    }
}
export default FilterComponent;