import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core';
import Service from "../Networking/networkutils";
import Headers from '../ContractorPlanning/Headers';

const classes = (theme) => ({

});

class GetChildTableData extends Component {
    _isMounted = false;
    constructor(props) {
        super();
        this.state = {
            isLoadChildTable: true, childTableData: [], workOrderNo: props.parentProps.Work_Order
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getData(this.state.workOrderNo);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getData = (workOrderNo) => {
        var categoryresult = [];
        var preconresult = [];
        return (Service.get('/bb/api/surface/category/?Work_Order=' + workOrderNo)
            .then((categoryresponse) => {
                categoryresult = categoryresponse.data.map(s => ({ surface_category: s.surface_category, length: s.length, width: s.width }));
                Service.get('/bb/api/precon/?Work_Order=' + workOrderNo).then(preconresponse => {
                    preconresult = preconresponse.data.map(s => ({ License_Type: s.License_Type, Special_conditions_or_remarks: s.Special_conditions_or_remarks }));
                    var finalData = [...categoryresult];
                    for (var i = 0; i < finalData.length; i++) {
                        finalData[i].License_Type = preconresult[0].License_Type;
                        finalData[i].Special_conditions_or_remarks = preconresult[0].Special_conditions_or_remarks;
                    }
                    this.setState({ childTableData: finalData, isLoadChildTable: false });
                }).catch((preconerror) => {
                    this.props.enqueueSnackbar('Something went wrong while fetching Surface Category details !', { variant: 'error' });
                    this.setState({ childTableData: [], isLoadChildTable: false });
                });
            }).catch((categoryerror) => {
                this.props.enqueueSnackbar('Something went wrong while fetching Surface Category details !', { variant: 'error' });
                this.setState({ childTableData: [], isLoadChildTable: false });
            }));
    }

    render() {
        return (
            <div style={{ padding: '10px 0px 10px 50px' }}>
                <MaterialTable
                    columns={[
                        { title: 'Licence Type (T2/ T3)', field: 'License_Type', hidden: this.isHideCols, headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 } },
                        { title: 'Job Remarks', field: 'Special_conditions_or_remarks', hidden: this.isHideCols, headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 } },
                        { title: 'Surface Category', field: 'surface_category', lookup: Headers.lookupSurfaceCategory, headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 } },
                        { title: 'Opening Length', field: 'length', headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 } },
                        { title: 'Opening Width', field: 'width', headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 } },
                    ]}
                    isLoading={this.state.isLoadChildTable}
                    data={this.state.childTableData}
                    options={{
                        search: false,
                        showTitle: false,
                        toolbar: false,
                        paging: false,
                        header: true,
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        }
                    }}
                    components={{
                        Container: props => props.children
                    }}
                />
            </div>
        );
    }
}
export default withStyles(classes)(withSnackbar(GetChildTableData));