import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import Service from "../Networking/networkutils";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const bbColorTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#005D99',
        },
        secondary: {
            main: '#0091B5',
        },
    }
})

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://balfourbeatty.com/">
                Balfour Beatty plc
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random?work,office)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default class Login extends Component {

    componentDidMount() {
        if (localStorage.getItem("bb_gni_token")) {
            window.location.href = '/'
        }
    }
    render() {
        return (
            <div>
                <SignInSide />
            </div>
        )
    }
}

function SignInSide() {
    const classes = useStyles();
    const [wrongData, setValue] = React.useState(false);
    const [failMessage, setFail] = React.useState(false);


    const handleSubmit = e => {
        e.preventDefault();
        const data = new FormData(e.target);
        const dataJson = Object.fromEntries(data);
        const username = dataJson.email.toLowerCase();
        const password = dataJson.password;
        if (username === 'pedctooltesting' && password === 'Reinstatement.2021') {
            setValue(false);
            localStorage.setItem("bb_gni_user", true);
            window.location.href = '/home';
        } else {
            localStorage.removeItem("bb_gni_user");
            setFail("Invalid username or password.");
            setValue(true);
        }
    };

    return (
        <ThemeProvider theme={bbColorTheme}>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <img alt={'Balfour Beatty'} style={{ width: '100%' }} src={process.env.PUBLIC_URL + '/bb.png'} />
                        <Avatar className={classes.avatar} >
                            <LockOutlinedIcon align="center" />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign In
                        </Typography>
                        <form className={classes.form} onSubmit={handleSubmit} noValidate>
                            <TextField variant="outlined" margin="normal" required fullWidth id="email" label="User Name" name="email" autoComplete="text" autoFocus />
                            <TextField variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
                            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                            {wrongData === true ? (
                                <p style={{ color: "red", fontSize: 14 }}>
                                    {failMessage}
                                </p>
                            ) : null}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Sign In
                            </Button>
                            {/* <Grid container>
                                <Grid item xs>
                                    <Link href="https://bb-rmp.appspot.com/auth/password_reset/" variant="body2">
                                        Forgot password?
                                </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/signup" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid> */}
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}