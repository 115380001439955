import React, { Component } from 'react';
// import { CircleToBlockLoading } from 'react-loadingg';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core';
import Service from "../Networking/networkutils";

const classes = (theme) => ({
});

class ContractorPlanningTable extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoadMainTable: true, data: [] };
    }
    componentDidMount() {
        this.getData();
    }
    getData() {
        const searchWorkOrderNo = this.props.stateVariable.state.searchWorkOrderNo;
        if (searchWorkOrderNo) {
            Service.get('/bb/api/planning2/noprecon/?Work_Order=' + searchWorkOrderNo).then(res => {
                if (res.data.length === 1) {
                    var tab = isNaN(parseInt(res.data[0].Work_Type) - 1) ? 0 : parseInt(res.data[0].Work_Type) - 1;
                    this.setState({
                        data: res.data,
                        isLoadMainTable: false
                    });
                    this.props.stateVariable.updateTab(tab);
                } else {
                    this.props.enqueueSnackbar('Searched work order number not found!', { variant: 'info' });
                    this.setState({ Value: 0, data: [], isLoadMainTable: false });
                }
            }).catch(error => {
                this.props.enqueueSnackbar('Something went wrong while searching for the work order number!', { variant: 'error' });
                this.setState({ Value: 0, data: [], isLoadMainTable: false });
            });
        }
        else {
            Service.get('/bb/api/planning2/noprecon/?Work_Type=' + this.props.WorkType + '&is_completed=' + this.props.IsCompleted)
                .then(res => {
                    this.setState({ data: res.data, isLoadMainTable: false });
                })
                .catch(error => {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                    this.setState({ isLoadMainTable: false });
                });
        }
    }
    render() {
        return (
            <div className={classes.root}>
                <div>
                    <MaterialTable
                        title={this.props.title}
                        columns={this.props.columns}
                        isLoading={this.state.isLoadMainTable}
                        data={this.state.data}
                        onRowClick={((evt, selectedRow) => (this.props.loadPreConstructionForm) ? this.props.stateVariable.loadPreConstructionForm(evt, selectedRow) : evt.preventDefault())}
                        options={{
                            pageSize: 20,
                            search: true,
                            headerStyle: { position: 'sticky', top: 0 },
                            maxBodyHeight: Math.round(window.screen.height - window.screen.height * 0.45),
                            filtering: true,
                            sorting: true,
                            doubleHorizontalScroll: true,
                            rowStyle: rowData => ({
                                color: getColor(rowData),
                                backgroundColor: (rowData.rowStyle) ? '#FFF' : '',
                            })
                        }}
                    />
                </div>
            </div >
        );
    }
}

function getColor(rowData) {
    if (rowData.rowStyle || rowData.status === 1) {
        return "#008000";
    }
    else if (rowData.Deletedrow || rowData.status === 3) {
        return "#FF0000";
    }
    else if (rowData.status === 2 || rowData.status === 0) {
        return "#FFA500";
    }
    else {
        return "";
    }
}

export default withStyles(classes)(withSnackbar(ContractorPlanningTable));