import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { withStyles, Grid } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
// import { CircleToBlockLoading } from 'react-loadingg';

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});
class JobPlan extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            jobplanData: [],
            crewData: [],
            jobPlanLoader: true,
            crewDataLoader: true,
            reinstatingAgentLoader: true,
            reinstatingCrewLoader: true,
            reinstatingAgentData: [],
            reinstatingCrewData: []
        }
    }
    insertReinstatingAgent = (newData, resolve) => {
        newData.name = (newData.name) ? newData.name.trim() : "";
        Service.post('/bb/api/reinstating/agent/', { data: newData })
            .then(res => {
                this.setState({ reinstatingAgentData: [...this.state.reinstatingAgentData, res.data] });
                this.props.enqueueSnackbar('Data saved successfully!', { variant: 'success' });
                resolve();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });
    }
    updateReinstatingAgent = (newData, oldData, resolve) => {
        newData.name = (newData.name) ? newData.name.trim() : "";
        Service.put('/bb/api/reinstating/agent/' + newData.id, { data: newData })
            .then(res => {
                const dataUpdate = [...this.state.reinstatingAgentData];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                this.setState({ reinstatingAgentData: [...dataUpdate] });
                this.props.enqueueSnackbar('Data updated successfully!', { variant: 'success' });
                resolve();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });
    }
    deleteReinstatingAgent = (oldData, resolve) => {
        Service.delete('/bb/api/reinstating/agent/' + oldData.id + '/')
            .then(res => {
                const dataDelete = [...this.state.reinstatingAgentData];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                this.setState({ reinstatingAgentData: [...dataDelete] });
                this.props.enqueueSnackbar('Data deleted successfully!', { variant: 'success' });
                resolve();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });
    }
    insertReinstatingCrew = (newData, resolve) => {
        newData.name = (newData.name) ? newData.name.trim() : "";
        Service.post('/bb/api/reinstating/crew/', { data: newData })
            .then(res => {
                this.setState({ reinstatingCrewData: [...this.state.reinstatingCrewData, res.data] });
                this.props.enqueueSnackbar('Data saved successfully!', { variant: 'success' });
                resolve();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });
    }
    updateReinstatingCrew = (newData, oldData, resolve) => {
        newData.name = (newData.name) ? newData.name.trim() : "";
        Service.put('/bb/api/reinstating/crew/' + newData.id, { data: newData })
            .then(res => {
                const dataUpdate = [...this.state.reinstatingCrewData];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                this.setState({ reinstatingCrewData: [...dataUpdate] });
                this.props.enqueueSnackbar('Data updated successfully!', { variant: 'success' });
                resolve();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });

    }
    deleteReinstatingCrew = (oldData, resolve) => {
        Service.delete('/bb/api/reinstating/crew/' + oldData.id + '/')
            .then(res => {
                const dataDelete = [...this.state.reinstatingCrewData];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                this.setState({ reinstatingCrewData: [...dataDelete] });
                this.props.enqueueSnackbar('Data deleted successfully!', { variant: 'success' });
                resolve();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });
    }
    insertJobPlanHoursData = (newData, resolve) => {
        Service.post('/bb/api/jobplanhour/', { data: newData })
            .then(res => {
                resolve();
                this.props.enqueueSnackbar('Data saved successfully!', { variant: 'success' });
                this.getJobPlanHoursData();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });
    }
    updateJobPlanHoursData = (newData, oldData, resolve) => {
        Service.put('/bb/api/jobplanhour/' + newData.id, { data: newData })
            .then(res => {
                resolve();
                this.props.enqueueSnackbar('Data updated successfully!', { variant: 'success' });
                this.getJobPlanHoursData();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });

    }
    deleteJobPlanHoursData = (oldData, resolve) => {
        Service.delete('/bb/api/jobplanhour/' + oldData.id + '/')
            .then(res => {
                resolve();
                this.props.enqueueSnackbar('Data deleted successfully!', { variant: 'success' });
                this.getJobPlanHoursData();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });
        this.fecthData();
    }
    insertCellsbyCrewData = (newData, resolve) => {
        newData.agent = (newData.agent) ? newData.agent.trim() : "";
        newData.cell = (newData.cell) ? newData.cell.trim() : "";
        newData.crew = (newData.crew) ? newData.crew.trim() : "";
        Service.post('/bb/api/cellsby/', { data: newData })
            .then(res => {
                resolve();
                this.props.enqueueSnackbar('Data saved successfully!', { variant: 'success' });
                this.getCellsbyData();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });
    }
    updateCellsbyCrewData = (newData, oldData, resolve) => {
        newData.agent = (newData.agent) ? newData.agent.trim() : "";
        newData.cell = (newData.cell) ? newData.cell.trim() : "";
        newData.crew = (newData.crew) ? newData.crew.trim() : "";
        Service.put('/bb/api/cellsby/' + newData.id, { data: newData })
            .then(res => {
                resolve();
                this.props.enqueueSnackbar('Data updated successfully!', { variant: 'success' });
                this.getCellsbyData();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });

    }
    deleteCellsbyCrewData = (oldData, resolve) => {
        Service.delete('/bb/api/cellsby/' + oldData.id + '/')
            .then(res => {
                resolve();
                this.props.enqueueSnackbar('Data deleted successfully!', { variant: 'success' });
                this.getCellsbyData();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                resolve();
            });
        this.fecthData();
    }
    componentDidMount() {
        this._isMounted = true;
        this.getJobPlanHoursData();
        this.getCellsbyData();
        this.getReinstatingAgent();
        this.getReinstatingCrewData();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getJobPlanHoursData() {
        Service.get('/bb/api/jobplanhour/')
            .then(res => {
                if (this._isMounted)
                    this.setState({ jobplanData: res.data })
                this.setState({ jobPlanLoader: false })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                this.setState({ jobPlanLoader: false })
            });
    }
    getCellsbyData() {
        Service.get('/bb/api/cellsby/')
            .then(res => {
                if (this._isMounted)
                    this.setState({ crewData: res.data })
                this.setState({ crewDataLoader: false })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                this.setState({ crewDataLoader: false })
            });
    }
    getReinstatingAgent() {
        Service.get('/bb/api/reinstating/agent/')
            .then(res => {
                if (this._isMounted) {
                    this.setState({ reinstatingAgentData: res.data, reinstatingAgentLoader: false });
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                this.setState({ reinstatingAgentLoader: false });
            });
    }
    getReinstatingCrewData() {
        Service.get('/bb/api/reinstating/crew/')
            .then(res => {
                if (this._isMounted) {
                    this.setState({ reinstatingCrewData: res.data, reinstatingCrewLoader: false });
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                this.setState({ reinstatingCrewLoader: false });
            });
    }
    render() {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <MaterialTable
                            title="Job Plan Hours"
                            columns={[
                                { title: 'Job Plan', field: 'job_plan' },
                                { title: 'Revised', field: 'revised' },
                            ]}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        this.updateJobPlanHoursData(newData, oldData, resolve);
                                    }),
                                onRowAdd: (newData) =>
                                    new Promise((resolve) => {
                                        this.insertJobPlanHoursData(newData, resolve);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        this.deleteJobPlanHoursData(oldData, resolve)
                                    }),
                            }}
                            data={this.state.jobplanData}
                            options={{
                                actionsColumnIndex: -1,
                                pageSize: 20,
                                search: true,
                                headerStyle: { position: 'sticky', top: 0 },
                                maxBodyHeight: Math.round(window.screen.height - window.screen.height * 0.45),
                                filtering: true,
                                sorting: true,
                                doubleHorizontalScroll: true,
                            }}
                            isLoading={this.state.jobPlanLoader}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MaterialTable
                            title="Cells by Crew and Agent"
                            columns={[
                                { title: 'Crew', field: 'crew' },
                                { title: 'Cell', field: 'cell' },
                                { title: 'Agent', field: 'agent' },
                            ]}
                            data={this.state.crewData}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        this.updateCellsbyCrewData(newData, oldData, resolve);
                                    }),
                                onRowAdd: (newData) =>
                                    new Promise((resolve) => {
                                        this.insertCellsbyCrewData(newData, resolve);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        this.deleteCellsbyCrewData(oldData, resolve)
                                    }),
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                pageSize: 20,
                                search: true,
                                headerStyle: { position: 'sticky', top: 0 },
                                maxBodyHeight: Math.round(window.screen.height - window.screen.height * 0.45),
                                filtering: true,
                                sorting: true,
                                doubleHorizontalScroll: true,
                            }}
                            isLoading={this.state.crewDataLoader}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MaterialTable
                            title="Reinstating Agent"
                            columns={[
                                { title: 'Name', field: 'name' },
                            ]}
                            data={this.state.reinstatingAgentData}
                            options={{
                                actionsColumnIndex: -1,
                                pageSize: 20,
                                search: true,
                                headerStyle: { position: 'sticky', top: 0 },
                                maxBodyHeight: Math.round(window.screen.height - window.screen.height * 0.45),
                                filtering: true,
                                sorting: true,
                                doubleHorizontalScroll: true,
                            }}
                            isLoading={this.state.reinstatingAgentLoader}
                            editable={{
                                onRowAdd: newData =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            this.insertReinstatingAgent(newData, resolve);
                                        }, 1000)
                                    }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            this.updateReinstatingAgent(newData, oldData, resolve);
                                        }, 1000)
                                    }),
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            this.deleteReinstatingAgent(oldData, resolve);
                                        }, 1000)
                                    }),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MaterialTable
                            title="Reinstating Crew"
                            columns={[
                                { title: 'Name', field: 'name' },
                            ]}
                            data={this.state.reinstatingCrewData}
                            options={{
                                actionsColumnIndex: -1,
                                pageSize: 20,
                                search: true,
                                headerStyle: { position: 'sticky', top: 0 },
                                maxBodyHeight: Math.round(window.screen.height - window.screen.height * 0.45),
                                filtering: true,
                                sorting: true,
                                doubleHorizontalScroll: true,
                            }}
                            isLoading={this.state.reinstatingCrewDataLoader}
                            editable={{
                                onRowAdd: newData =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            this.insertReinstatingCrew(newData, resolve);
                                        }, 1000)
                                    }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            this.updateReinstatingCrew(newData, oldData, resolve);
                                        }, 1000)
                                    }),
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            this.deleteReinstatingCrew(oldData, resolve);
                                        }, 1000)
                                    }),
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withStyles(useStyles)(withSnackbar(JobPlan));