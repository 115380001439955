import React from 'react'
import ReactDOM from 'react-dom';
import './index.css';
import { SnackbarProvider } from 'notistack';
import App from './App';
import ReactBreakpoints from 'react-breakpoints'
import * as serviceWorker from './serviceWorker';

const breakpoints = {
    mobile: 320,
    mobileLandscape: 480,
    tablet: 768,
    tabletLandscape: 1024,
    desktop: 1200,
    desktopLarge: 1500,
    desktopWide: 1920,
}

ReactDOM.render(<SnackbarProvider>
    <ReactBreakpoints breakpoints={breakpoints}>
        <App />
    </ReactBreakpoints>
</SnackbarProvider>, document.getElementById('root'));

serviceWorker.unregister();
