import React from 'react';
import Moment from 'react-moment';
import Headers from '../ContractorPlanning/Headers';

class ContractorPlanningHeaders {


    constructor() {

        // Enum
        this.lookupWorkTypes = { 1: "Services Appointments", 2: "Maintenance Appointments", 3: "Maint CTSR & CTSPREV", 4: "Maint Cst Contribution Works", 5: "Program Works", 6: "Maint Other", 7: "Construction Projects", 8: "DRs", 9: "Civil Crew" };
        // Enum

        //Look Up
        this.lookupJobPlan = { 1: 'RPMO', 2: 'LASP', 3: 'RLSP', 4: 'RESV', 5: 'RFBV', 6: 'RLSP-GB', 7: 'RASP', 8: 'RFTV', 9: 'UGMN-DRI', 10: 'ALMN', 11: 'LASB', 12: 'LADO', 13: 'LACR', 14: 'LACI', 15: 'LAPS', 16: 'CTSPPROJ', 17: 'EXMC', 18: 'ACSP', 19: 'OTMN', 20: 'LAMN', 21: 'LACS', 22: 'RLSP-PEIP', 23: 'CTSP', 24: 'RDSR', 25: 'LADS', 26: 'CTMN-P', 27: 'RPMO-P', 28: 'RDMB', 29: 'CTSP-L', 30: 'LAFM', 31: 'LACM', 32: 'CTMN', 33: 'CTSPREV', 34: 'RASP-PEIP', 35: 'CTSG', 36: 'CTSPNOGP', 37: 'ALSP', 38: 'CTSR', 39: 'CTSIPROJ', 40: 'RFMN', 41: 'RLSP-P', 42: 'RNTS', 43: 'ALSP-S', 44: 'LASM', 45: 'LADS-X', 46: 'CTSO', 47: 'LACB', 48: 'ALSP-EX', 49: 'LRC2', 50: 'USSI', 51: 'CTSG-NCHG', 52: 'CTSPOPS', 53: 'RCSDRP', 54: 'RCSI', 55: 'EXMCCRAS', 56: 'CRAS', 57: 'RCSD' };
        this.lookupWOStatus = { 1: 'Unscheduled', 2: 'Ready to Schedule', 3: 'Ready to Dispatch', 4: 'Dispatched' };
        this.lookupTaskStatus = { 1: 'UNSCHD', 2: 'SCHD', '3': 'RSCH', 4: 'HOLD', 5: 'REJECTED', 6: 'ONSITE', 7: 'PCOMP', 8: 'UNAPPR', 9: 'ABORT', 10: 'DISPATCHED', 11: 'RECEIVED' };
        this.lookupCommon = { 1: 'NOT REQUIRED', 2: 'REQUIRED', 3: 'COMPLETED' };
        this.lookupSurfaceCategory = {
            2: 'Unmade ground', 1: 'Grass', 3: 'Concrete Path', 4: 'Concrete Driveway', 5: 'Concrete road', 6: 'Matcrete', 7: 'Antique cobble', 8: 'Antique granite', 9: 'Concrete Flag', 10: 'Concrete Sett', 11: 'Tarmac Road', 12: 'Tarmac Drive', 13: 'Tarmac Path', 14: 'Asphalt Road', 15: 'Asphalt Driveway', 16: 'Asphalt Path', 17: 'Granite Flag', 18: 'Granite Sett', 19: 'Brick Path', 20: 'Brick driveway', 21: 'Brick Road', 22: 'Anti Skid', 23: 'Line Marking', 24: 'Road Markings', 25: 'Concrete Seals', 26: 'Concrete kerb'
        };
        this.lookupPrivatePublic = {
            1: 'Public', 2: 'Private'
        };
        this.lookup_OverheadESBPresentOnMap = {
            1: 'LV', 2: 'MV', 3: 'HV', 4: 'No'
        };
        this.lookup_RoadType = {
            1: 'Single', 2: 'Dual', 3: 'One Way', 4: 'Two Way'
        };
        this.lookupRestrictedHours = {
            1: '1.Normal hours 8.00am – 5.00pm', 2: '2.Restricted hours 10.00am – 4.00pm', 3: '3.Out of hours Mon - Fri 8.00pm – 11.00pm', 4: '4.Out of hours Mon – Wed 7.30pm – 11.00pm', 5: '5.Out of hours 7.00pm – 7.00am', 6: '6.Weekend Sat – Sun 9.00am – 5.00pm', 7: '7.Weekend Sun 9.00am – 5.00pm', 8: '8.Weekend Sat 9.00am – 12.00am', 9: '9.Weekend Sat 7.30pm – 11.00pm', 10: '10.Outside of normal working hours', 11: '11.Other'
        };
        this.lookupFullBay_FullRoadReinstatement = {
            1: 'Yes', 2: 'No'
        };
        //Look Up

        this.numnerTypes = ["Work_Type", "Work_Order", "Project_WO_Code", "Days_Since_WO_Creation", "Days_Since_Telephone_Call_To_Cst", "Days_Since_Text_To_Cst", "Location_Code", "Days_Until_App_Start"];

        this.dateTypes = ["Created_Date", "Scheduled_Start", "Scheduled_Finish", "Appointment_Start", "Appointment_End", "License_Expiry_Date", "License_Valid_Date", "License_Ext_1_Submitted_Date", "License_Ext_1_Expiry", "License_Ext_2_Submitted_Date", "License_Ext_2_Expiry", "License_Ext_3_Submitted_Date", "License_Ext_3_Expiry", "License_Arrange_Submitted_Date"];
        this.lookupTypes = ["Job_Plan", 'Addit_License_Conds', 'Site_Work_Construction', "Aslaid_Submitted_By_Cntcr", "Cnstr_Pack_Issued", "Steel_Works", "Cnstr_Pack_Issued", "Site_Ready", "WO_Status", "Task_Status", "Letter_Req", "Customer_App_Arranged", "Licence_Arrange", "Pre_Con_Pack", "Pre_Con_Site_Visit", "Traffic_Plans", "Telephone_Call_To_Customer", "Txt_Customer", "Pre_Con_Card_Dropped", "Licence_Extension_1", "Licence_Extension_2", "Licence_Extension_3", "Schedule_Lines_Reviewed"];

        this.servicesAppointmentsColumns = [
            {
                field: "pre_con_form",
                title: "pre_con_form",
                hidden: true
            },
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Full_Address",
                title: "Full Address",
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: 'date',
                defaultSort: "desc",
                render: rowData => DateCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell"
            },
            {
                field: "District",
                title: "District",
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: 'date',
                render: rowData => DateCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: 'date',
                render: rowData => DateCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment Start",
                type: 'date',
                render: rowData => DateCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: 'date',
                render: rowData => DateCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Until_App_Start",
                title: "Days Until App start",
                type: 'numeric',
                headerStyle: { minWidth: 190 }, cellStyle: { minWidth: 190 }
            },
            {
                field: "License_Arrange_Submitted_Date",
                title: "License Arrange Submitted Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Arrange_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Comment",
                title: "License Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer App Arranged Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 },
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Customer_App_Arranged",
                title: "Customer Appointment Arranged",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 },
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Schedule_Lines_Reviewed",
                title: "Schedule Lines Reviewed",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 },
            }
        ];
        this.servicesAppointmentsColumns1 = [
            {
                field: "pre_con_form",
                title: "pre_con_form",
                hidden: true
            },
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Work_Order"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Full_Address",
                title: "Full Address",
                render: rowData => HighlightCell(rowData, "Full_Address"),
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: 'date',
                defaultSort: "desc",
                render: rowData => DateHighlightCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell",
                render: rowData => HighlightCell(rowData, "Cell")
            },
            {
                field: "District",
                title: "District",
                render: rowData => HighlightCell(rowData, "District"),
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                lookup: this.lookupJobPlan,
                render: rowData => HighlightLookupCell(rowData, "Job_Plan"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                render: rowData => HighlightLookupCell(rowData, "WO_Status"),
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                render: rowData => HighlightLookupCell(rowData, "Task_Status"),
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment _Start",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                render: rowData => HighlightCell(rowData, "Appointment_Status_Reason"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Until_App_Start",
                title: "Days Until App start",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Days_Until_App_Start"),
                headerStyle: { minWidth: 190 }, cellStyle: { minWidth: 190 }
            },
            {
                field: "License_Arrange_Submitted_Date",
                title: "License Arrange Submitted Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Arrange_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Comment",
                title: "License Comment",
                render: rowData => HighlightCell(rowData, "License_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_1_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_2_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_3_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                render: rowData => HighlightCell(rowData, "Pre_Con_Visit_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                render: rowData => HighlightCell(rowData, "Traffic_Plan_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer App Arranged Comment",
                render: rowData => HighlightCell(rowData, "Customer_App_Arranged_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Location_Code"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                render: rowData => HighlightCell(rowData, "Crew_Description"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Customer_App_Arranged",
                title: "Customer Appointment Arranged",
                render: rowData => HighlightLookupCell(rowData, "Customer_App_Arranged"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                render: rowData => HighlightLookupCell(rowData, "Licence_Arrange"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Pack"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Site_Visit"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                render: rowData => HighlightLookupCell(rowData, "Traffic_Plans"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Schedule_Lines_Reviewed",
                title: "Schedule Lines Reviewed",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 },
                render: rowData => HighlightLookupCell(rowData, "Schedule_Lines_Reviewed")
            }
        ];
        this.maintenanceAppointmentsColumns = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Full_Address",
                title: "Full Address",
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell"
            },
            {
                field: "District",
                title: "District",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Project_WO_Code",
                title: "Project Wo Code",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "WO_Status",
                title: "Wo Status",
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment Start",
                type: "date",
                render: rowData => DateCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: "date",
                render: rowData => DateCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "Days_Since_WO_Creation",
                title: "Days Since Wo Creation",
                type: 'numeric',
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Since_Telephone_Call_To_Cst",
                title: "Days Since Telephone Call To Cst",
                type: 'numeric',
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Days_Since_Text_To_Cst",
                title: "Days Since Text To Cst",
                type: 'numeric',
                headerStyle: { minWidth: 400 }, cellStyle: { minWidth: 400 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Cst Appointment Arranged Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Comment",
                title: "License Arrange Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Letter_Req",
                title: "Letter Req",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Customer_App_Arranged",
                title: "Customer Appointment Arranged",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
            },
            {
                field: "Licence_Arrange",
                title: "License Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Telephone_Call_To_Customer",
                title: "Telephone Call To Customer",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
            },
            {
                field: "Txt_Customer",
                title: "Txt Customer",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Card_Dropped",
                title: "Pre Con Card Dropped",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Schedule_Lines_Reviewed",
                title: "Schedule Lines Reviewed",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 },
            }
        ];
        this.maintenanceAppointmentsColumns1 = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                render: rowData => HighlightCell(rowData, "Work_Order")
            },
            {
                field: "Full_Address",
                title: "Full Address",
                render: rowData => HighlightCell(rowData, "Full_Address"),
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateHighlightCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell",
                render: rowData => HighlightCell(rowData, "Cell")
            },
            {
                field: "District",
                title: "District",
                render: rowData => HighlightCell(rowData, "District"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                lookup: this.lookupJobPlan,
                render: rowData => HighlightLookupCell(rowData, "Job_Plan"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Project_WO_Code",
                title: "Project Wo Code",
                render: rowData => HighlightCell(rowData, "Project_WO_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "WO_Status",
                title: "Wo Status",
                lookup: this.lookupWOStatus,
                render: rowData => HighlightLookupCell(rowData, "WO_Status"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                lookup: this.lookupTaskStatus,
                render: rowData => HighlightLookupCell(rowData, "Task_Status"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment Start",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                render: rowData => HighlightCell(rowData, "Appointment_Status_Reason"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "Days_Since_WO_Creation",
                title: "Days Since Wo Creation",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Days_Since_WO_Creation"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Since_Telephone_Call_To_Cst",
                title: "Days Since Telephone Call To Cst",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Days_Since_Telephone_Call_To_Cst"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Days_Since_Text_To_Cst",
                title: "Days Since Text To Cst",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Days_Since_Text_To_Cst"),
                headerStyle: { minWidth: 400 }, cellStyle: { minWidth: 400 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Cst Appointment Arranged Comment",
                render: rowData => HighlightCell(rowData, "Customer_App_Arranged"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Comment",
                title: "License Arrange Comment",
                render: rowData => HighlightCell(rowData, "License_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_1_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_2_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_3_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                render: rowData => HighlightCell(rowData, "Pre_Con_Visit_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                render: rowData => HighlightCell(rowData, "Traffic_Plan_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Location_Code"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                render: rowData => HighlightCell(rowData, "Crew_Description"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Letter_Req",
                title: "Letter Req",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                render: rowData => HighlightLookupCell(rowData, "Letter_Req")
            },
            {
                field: "Customer_App_Arranged",
                title: "Customer Appointment Arranged",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
                render: rowData => HighlightLookupCell(rowData, "Customer_App_Arranged")

            },
            {
                field: "Licence_Arrange",
                title: "License Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                render: rowData => HighlightLookupCell(rowData, "Licence_Arrange"),
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Pack"),
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Site_Visit"),
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                render: rowData => HighlightLookupCell(rowData, "Traffic_Plans"),
            },
            {
                field: "Telephone_Call_To_Customer",
                title: "Telephone Call To Customer",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
                render: rowData => HighlightLookupCell(rowData, "Telephone_Call_to_Customer"),
            },
            {
                field: "Txt_Customer",
                title: "Txt Customer",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                render: rowData => HighlightLookupCell(rowData, "Txt_Customer")
            },
            {
                field: "Pre_Con_Card_Dropped",
                title: "Pre Con Card Dropped",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Card_Dropped")
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_1")
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_2"),
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_3"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Schedule_Lines_Reviewed",
                title: "Schedule Lines Reviewed",
                render: rowData => HighlightLookupCell(rowData, "Schedule_Lines_Reviewed"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 },
            }
        ];
        this.maintCTSRCTSPREVColumns = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell"
            },
            {
                field: "District",
                title: "District",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Project_WO_Code",
                title: "Project Wo Code",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "WO_Status",
                title: "Wo Status",
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment Start",
                type: "date",
                render: rowData => DateCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: "date",
                render: rowData => DateCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "Days_Since_WO_Creation",
                title: "Days Since Wo Creation",
                type: 'numeric',
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Since_Telephone_Call_To_Cst",
                title: "Days Since Telephone Call To Cst",
                type: 'numeric',
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Days_Since_Text_To_Cst",
                title: "Days Since Text To Cst",
                type: 'numeric',
                headerStyle: { minWidth: 400 }, cellStyle: { minWidth: 400 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Cst Appointment Arranged Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Comment",
                title: "License Arrange Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Licence_Arrange",
                title: "License Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Schedule_Lines_Reviewed",
                title: "Schedule Lines Reviewed",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 },
            }
        ];
        this.maintCTSRCTSPREVColumns1 = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                render: rowData => HighlightCell(rowData, "Work_Order")
            },
            {
                field: "Full_Address",
                title: "Full Address",
                render: rowData => HighlightCell(rowData, "Full_Address"),
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateHighlightCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell",
                render: rowData => HighlightCell(rowData, "Cell")
            },
            {
                field: "District",
                title: "District",
                render: rowData => HighlightCell(rowData, "District"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                render: rowData => HighlightLookupCell(rowData, "Job_Plan"),
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Project_WO_Code",
                title: "Project Wo Code",
                render: rowData => HighlightCell(rowData, "Project_WO_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "WO_Status",
                title: "Wo Status",
                render: rowData => HighlightLookupCell(rowData, "WO_Status"),
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                render: rowData => HighlightLookupCell(rowData, "Task_Status"),
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment Start",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                render: rowData => HighlightCell(rowData, "Appointment_Status_Reason"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "Days_Since_WO_Creation",
                title: "Days Since Wo Creation",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Days_Since_WO_Creation"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Since_Telephone_Call_To_Cst",
                title: "Days Since Telephone Call To Cst",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Days_Since_Telephone_Call_To_Cst"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Days_Since_Text_To_Cst",
                title: "Days Since Text To Cst",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Days_Since_Text_To_Cst"),
                headerStyle: { minWidth: 400 }, cellStyle: { minWidth: 400 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Cst Appointment Arranged Comment",
                render: rowData => HighlightCell(rowData, "Customer_App_Arranged"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Comment",
                title: "License Arrange Comment",
                render: rowData => HighlightCell(rowData, "License_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_1_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_2_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                render: rowData => HighlightCell(rowData, "Pre_Con_Visit_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                render: rowData => HighlightCell(rowData, "Traffic_Plan_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Location_Code"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                render: rowData => HighlightCell(rowData, "Crew_Description"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Licence_Arrange",
                title: "License Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                render: rowData => HighlightLookupCell(rowData, "Licence_Arrange")
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Pack"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Site_Visit"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_1"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_2"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_3"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Schedule_Lines_Reviewed",
                title: "Schedule Lines Reviewed",
                render: rowData => HighlightLookupCell(rowData, "Schedule_Lines_Reviewed"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 },
            }
        ];
        this.maintCstContributionWorksColumns = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: 'date',
                defaultSort: "desc",
                render: rowData => DateCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell"
            },
            {
                field: "District",
                title: "District",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Project_WO_Code",
                title: "Project WO Code",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: 'date',
                render: rowData => DateCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: 'date',
                render: rowData => DateCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment Start",
                type: 'date',
                render: rowData => DateCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: 'date',
                render: rowData => DateCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "Days_Since_WO_Creation",
                title: "Days Since WO Creation",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Since_Telephone_Call_To_Cst",
                title: "Days Since Telephone Call To Cst",
                type: 'numeric',
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Days_Since_Text_To_Cst",
                title: "Days Since Text To Cst",
                headerStyle: { minWidth: 400 }, cellStyle: { minWidth: 400 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer Appointment Arranged Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Comment",
                title: "License Arrange Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry Date",
                render: rowData => DateCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: 'date',
                render: rowData => DateCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry Date",
                render: rowData => DateCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Customer_App_Arranged",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
                title: "Customer Appointment Arranged"
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Schedule_Lines_Reviewed",
                title: "Schedule Lines Reviewed",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 },
            }
        ];
        this.maintCstContributionWorksColumns1 = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Work_Order"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                render: rowData => HighlightCell(rowData, "Full_Address"),
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: 'date',
                defaultSort: "desc",
                render: rowData => DateHighlightCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell",
                render: rowData => HighlightCell(rowData, "Cell")
            },
            {
                field: "District",
                title: "District",
                render: rowData => HighlightCell(rowData, "District"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                render: rowData => HighlightLookupCell(rowData, "Job_Plan"),
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Project_WO_Code",
                title: "Project WO Code",
                render: rowData => HighlightCell(rowData, "Project_WO_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                render: rowData => HighlightLookupCell(rowData, "WO_Status"),
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                render: rowData => HighlightLookupCell(rowData, "Task_Status"),
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment Start",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                render: rowData => HighlightCell(rowData, "Appointment_Status_Reason"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "Days_Since_WO_Creation",
                title: "Days Since WO Creation",
                render: rowData => HighlightCell(rowData, "Days_Since_WO_Creation"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Since_Telephone_Call_To_Cst",
                title: "Days Since Telephone Call To Cst",
                render: rowData => HighlightCell(rowData, "Days_Since_Telephone_Call_To_Cst"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 },
                type: 'numeric',
            },
            {
                field: "Days_Since_Text_To_Cst",
                title: "Days Since Text To Cst",
                render: rowData => HighlightCell(rowData, "Days_Since_Text_To_Cst"),
                headerStyle: { minWidth: 400 }, cellStyle: { minWidth: 400 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer Appointment Arranged Comment",
                render: rowData => HighlightCell(rowData, "Customer_App_Arranged_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Comment",
                title: "License Arrange Comment",
                render: rowData => HighlightCell(rowData, "License_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_1_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_2_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry Date",
                type: 'date',
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_3_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                render: rowData => HighlightCell(rowData, "Pre_Con_Visit_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                render: rowData => HighlightCell(rowData, "Traffic_Plan_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Location_Code"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                render: rowData => HighlightCell(rowData, "Crew_Description"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Customer_App_Arranged",
                title: "Customer Appointment Arranged",
                render: rowData => HighlightLookupCell(rowData, "Customer_App_Arranged"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },

            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                render: rowData => HighlightLookupCell(rowData, "Licence_Arrange")
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Pack"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Site_Visit"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_1"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_2"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_3"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Schedule_Lines_Reviewed",
                title: "Schedule Lines Reviewed",
                render: rowData => HighlightLookupCell(rowData, "Schedule_Lines_Reviewed"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 },
            }
        ];
        this.programWorksColumns = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell"
            },
            {
                field: "District",
                title: "District",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Project_WO_Code",
                title: "Project No",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Program_Code",
                title: "Program Code",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Until_App_Start",
                title: "Days Until App Start",
                type: 'numeric',
                headerStyle: { minWidth: 190 }, cellStyle: { minWidth: 190 }
            },
            {
                field: "License_Arrange_Submitted_Date",
                title: "License Arrange Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Arrange_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Comment",
                title: "License Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Extension 1 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License 2 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer App Arranged Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Customer_App_Arranged",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
                title: "Customer Appointment Arranged"
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
            },
            {
                field: "Aslaid_Submitted_By_Cntcr",
                title: "Aslaid Submitted By Cntcr",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Schedule_Lines_Reviewed",
                title: "Schedule Lines Reviewed",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 },
            }
        ];
        this.programWorksColumns1 = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Work_Order"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                render: rowData => HighlightCell(rowData, "Full_Address"),
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateHighlightCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell",
                render: rowData => HighlightCell(rowData, "Cell")
            },
            {
                field: "District",
                title: "District",
                render: rowData => HighlightCell(rowData, "District"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                render: rowData => HighlightLookupCell(rowData, "Job_Plan"),
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                render: rowData => HighlightLookupCell(rowData, "WO_Status"),
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                render: rowData => HighlightLookupCell(rowData, "Task_Status"),
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Project_WO_Code",
                title: "Project No",
                render: rowData => HighlightCell(rowData, "Project_WO_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Program_Code",
                title: "Program Code",
                render: rowData => HighlightCell(rowData, "Program_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Until_App_Start",
                title: "Days Until App Start",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Days_Until_App_Start"),
                headerStyle: { minWidth: 190 }, cellStyle: { minWidth: 190 }
            },
            {
                field: "License_Arrange_Submitted_Date",
                title: "License Arrange Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Arrange_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Comment",
                title: "License Comment",
                render: rowData => HighlightCell(rowData, "License_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_1_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Extension 1 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License 2 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_2_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_3_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                render: rowData => HighlightCell(rowData, "Pre_Con_Visit_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                render: rowData => HighlightCell(rowData, "Traffic_Plan_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer App Arranged Comment",
                render: rowData => HighlightCell(rowData, "Customer_App_Arranged_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Location_Code"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                render: rowData => HighlightCell(rowData, "Crew_Description"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Customer_App_Arranged",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
                title: "Customer Appointment Arranged",
                render: rowData => HighlightLookupCell(rowData, "Customer_App_Arranged")
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                render: rowData => HighlightLookupCell(rowData, "Licence_Arrange"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Pack"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Site_Visit"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                render: rowData => HighlightLookupCell(rowData, "Traffic_Plans"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Aslaid_Submitted_By_Cntcr",
                title: "Aslaid Submitted By Cntcr",
                render: rowData => HighlightLookupCell(rowData, "Aslaid_Submitted_By_Cntcr"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_1"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_2"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_3"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Schedule_Lines_Reviewed",
                title: "Schedule Lines Reviewed",
                render: rowData => HighlightLookupCell(rowData, "Schedule_Lines_Reviewed"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 225 }, cellStyle: { minWidth: 225 },
            }
        ];
        this.maintOtherColumns = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell"
            },
            {
                field: "District",
                title: "District",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Project_WO_Code",
                title: "Project WO Code",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment Start",
                type: "date",
                render: rowData => DateCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: "date",
                render: rowData => DateCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "Days_Since_WO_Creation",
                title: "Days Since WO Creation",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Since_Telephone_Call_To_Cst",
                title: "Days Since Telephone Call To Cst",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 },
                type: 'numeric',
            },
            {
                field: "Days_Since_Text_To_Cst",
                title: "Days Since Text To Cst",
                headerStyle: { minWidth: 400 }, cellStyle: { minWidth: 400 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer Appointment Arranged Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Comment",
                title: "License Arrange Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License 2 Ext Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            }
        ];
        this.maintOtherColumns1 = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Work_Order"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                render: rowData => HighlightCell(rowData, "Full_Address"),
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateHighlightCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell",
                render: rowData => HighlightCell(rowData, "Cell")
            },
            {
                field: "District",
                title: "District",
                render: rowData => HighlightCell(rowData, "District"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                render: rowData => HighlightLookupCell(rowData, "Job_Plan"),
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Project_WO_Code",
                title: "Project WO Code",
                render: rowData => HighlightCell(rowData, "Project_WO_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                render: rowData => HighlightLookupCell(rowData, "WO_Status"),
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                render: rowData => HighlightLookupCell(rowData, "Task_Status"),
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment Start",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                render: rowData => HighlightCell(rowData, "Appointment_Status_Reason"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "Days_Since_WO_Creation",
                title: "Days Since WO Creation",
                render: rowData => HighlightCell(rowData, "Days_Since_WO_Creation"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Since_Telephone_Call_To_Cst",
                title: "Days Since Telephone Call To Cst",
                render: rowData => HighlightCell(rowData, "Days_Since_Telephone_Call_To_Cst"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 },
                type: 'numeric',
            },
            {
                field: "Days_Since_Text_To_Cst",
                title: "Days Since Text To Cst",
                render: rowData => HighlightCell(rowData, "Days_Since_Text_To_Cst"),
                headerStyle: { minWidth: 400 }, cellStyle: { minWidth: 400 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer Appointment Arranged Comment",
                render: rowData => HighlightCell(rowData, "Customer_App_Arranged_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Comment",
                title: "License Arrange Comment",
                render: rowData => HighlightCell(rowData, "License_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_1_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License 2 Ext Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_2_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_3_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                render: rowData => HighlightCell(rowData, "Pre_Con_Visit_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                render: rowData => HighlightCell(rowData, "Traffic_Plan_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Location_Code"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                render: rowData => HighlightCell(rowData, "Crew_Description"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                render: rowData => HighlightLookupCell(rowData, "Licence_Arrange"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Pack"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Site_Visit"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                render: rowData => HighlightLookupCell(rowData, "Traffic_Plans"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_1"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_2"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_3"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            }
        ];
        this.constructionProjectsColumns = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell"
            },
            {
                field: "District",
                title: "District",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment Start",
                type: "date",
                render: rowData => DateCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: "date",
                render: rowData => DateCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Project_WO_Code",
                title: "Project No",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Program_Code",
                title: "Program Code",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Until_App_Start",
                title: "Days Until App Start",
                type: 'numeric',
                headerStyle: { minWidth: 190 }, cellStyle: { minWidth: 190 }
            },
            {
                field: "License_Arrange_Submitted_Date",
                title: "License Arrange Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Arrange_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Comment",
                title: "License Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License 3 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer App Arranged Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Customer_App_Arranged",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
                title: "Customer Appointment Arranged"
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            }
        ];
        this.constructionProjectsColumns1 = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Work_Order"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                render: rowData => HighlightCell(rowData, "Full_Address"),
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateHighlightCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell",
                render: rowData => HighlightCell(rowData, "Cell")
            },
            {
                field: "District",
                title: "District",
                render: rowData => HighlightCell(rowData, "District"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                render: rowData => HighlightLookupCell(rowData, "Job_Plan"),
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                render: rowData => HighlightLookupCell(rowData, "WO_Status"),
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                render: rowData => HighlightLookupCell(rowData, "Task_Status"),
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Appointment_Start",
                title: "Appointment Start",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Appointment_Start"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_End",
                title: "Appointment End",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Appointment_End"),
                headerStyle: { minWidth: 180 }, cellStyle: { minWidth: 180 }
            },
            {
                field: "Appointment_Status_Reason",
                title: "Appointment Status Reason",
                render: rowData => HighlightCell(rowData, "Appointment_Status_Reason"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Project_WO_Code",
                title: "Project No",
                render: rowData => HighlightCell(rowData, "Project_WO_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Program_Code",
                title: "Program Code",
                render: rowData => HighlightCell(rowData, "Program_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Until_App_Start",
                title: "Days Until App Start",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Days_Until_App_Start"),
                headerStyle: { minWidth: 190 }, cellStyle: { minWidth: 190 }
            },
            {
                field: "License_Arrange_Submitted_Date",
                title: "License Arrange Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Arrange_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Comment",
                title: "License Comment",
                render: rowData => HighlightCell(rowData, "License_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_1_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_2_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License 3 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_3_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                render: rowData => HighlightCell(rowData, "Pre_Con_Visit_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                render: rowData => HighlightCell(rowData, "Traffic_Plan_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer App Arranged Comment",
                render: rowData => HighlightCell(rowData, "Customer_App_Arranged_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Location_Code"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                render: rowData => HighlightCell(rowData, "Crew_Description"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Customer_App_Arranged",
                title: "Customer Appointment Arranged",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
                render: rowData => HighlightLookupCell(rowData, "Customer_App_Arranged")
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                render: rowData => HighlightLookupCell(rowData, "Licence_Arrange"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Pack"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Site_Visit"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                render: rowData => HighlightLookupCell(rowData, "Traffic_Plans"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                render: rowData => HighlightCell(rowData, "Licence_Extension_1"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                render: rowData => HighlightCell(rowData, "Licence_Extension_2"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                render: rowData => HighlightCell(rowData, "Licence_Extension_3"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            }
        ];
        this.dRsColumns = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell"
            },
            {
                field: "District",
                title: "District",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Project_WO_Code",
                title: "Project No",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Program_Code",
                title: "Program Code",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Oracle_Code",
                title: "Oracle Code",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Until_App_Start",
                title: "Days Until App start",
                type: 'numeric',
                headerStyle: { minWidth: 190 }, cellStyle: { minWidth: 190 }
            },
            {
                field: "License_Arrange_Submitted_Date",
                title: "License Arrange Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Arrange_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Comment",
                title: "License Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Extension 1 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Extension 1 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Extension 2 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer App Arranged Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Customer_App_Arranged",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
                title: "Customer Appointment Arranged"
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Site_Ready",
                title: "Site Ready",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Steel_Works",
                title: "Steel Works",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            }
        ];
        this.dRsColumns1 = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Work_Order"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                render: rowData => HighlightCell(rowData, "Full_Address"),
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateHighlightCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell",
                render: rowData => HighlightCell(rowData, "Cell")
            },
            {
                field: "District",
                title: "District",
                render: rowData => HighlightCell(rowData, "District"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                render: rowData => HighlightLookupCell(rowData, "Job_Plan"),
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                render: rowData => HighlightLookupCell(rowData, "WO_Status"),
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                render: rowData => HighlightLookupCell(rowData, "Task_Status"),
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Project_WO_Code",
                title: "Project No",
                render: rowData => HighlightCell(rowData, "Project_WO_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Program_Code",
                title: "Program Code",
                render: rowData => HighlightCell(rowData, "Program_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Oracle_Code",
                title: "Oracle Code",
                render: rowData => HighlightCell(rowData, "Oracle_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Until_App_Start",
                title: "Days Until App start",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Days_Until_App_Start"),
                headerStyle: { minWidth: 190 }, cellStyle: { minWidth: 190 }
            },
            {
                field: "License_Arrange_Submitted_Date",
                title: "License Arrange Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Arrange_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Comment",
                title: "License Comment",
                render: rowData => HighlightCell(rowData, "License_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Extension 1 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Extension 1 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_1_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Extension 2 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_2_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_3_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                render: rowData => HighlightCell(rowData, "Pre_Con_Visit_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                render: rowData => HighlightCell(rowData, "Traffic_Plan_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer App Arranged Comment",
                render: rowData => HighlightCell(rowData, "Customer_App_Arranged_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Location_Code"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                render: rowData => HighlightCell(rowData, "Crew_Description"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Customer_App_Arranged",
                title: "Customer Appointment Arranged",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 350 }, cellStyle: { minWidth: 350 },
                render: rowData => HighlightLookupCell(rowData, "Customer_App_Arranged")
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                render: rowData => HighlightLookupCell(rowData, "Licence_Arrange"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Pack"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Site_Visit",
                title: "Pre Con Site Visit",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Site_Visit"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                render: rowData => HighlightLookupCell(rowData, "Traffic_Plans"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_1",
                title: "Licence Extension 1",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_1"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_2",
                title: "Licence Extension 2",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_2"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Licence_Extension_3",
                title: "Licence Extension 3",
                render: rowData => HighlightLookupCell(rowData, "Licence_Extension_3"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Site_Ready",
                title: "Site Ready",
                render: rowData => HighlightLookupCell(rowData, "Site_Ready"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Steel_Works",
                title: "Steel Works",
                render: rowData => HighlightLookupCell(rowData, "Steel_Works"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            }
        ];
        this.civilCrewColumns = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "CC",
                title: "CC"
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell"
            },
            {
                field: "District",
                title: "District",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Project_WO_Code",
                title: "Project No",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Program_Code",
                title: "Program Code",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Oracle_Code",
                title: "Oracle Code",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Until_App_Start",
                title: "Days Until App start",
                type: 'numeric',
                headerStyle: { minWidth: 190 }, cellStyle: { minWidth: 190 }
            },
            {
                field: "License_Arrange_Submitted_Date",
                title: "License Arrange Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Arrange_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Comment",
                title: "License Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer App Arranged Comment",
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Addit_License_Conds",
                title: "Addit License Conds",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Cnstr_Pack_Issued",
                title: "Cnstr Pack Issued",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Site_Work_Construction",
                title: "Site Work Construction",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            }
        ];
        this.civilCrewColumns1 = [
            {
                field: "Work_Order",
                title: "Work Order",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Work_Order"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
            },
            {
                field: "Full_Address",
                title: "Full Address",
                render: rowData => HighlightCell(rowData, "Full_Address"),
                headerStyle: { minWidth: 430 }, cellStyle: { minWidth: 430 }
            },
            {
                field: "CC",
                title: "CC",
                render: rowData => HighlightCell(rowData, "CC")
            },
            {
                field: "Created_Date",
                title: "Created Date",
                type: "date",
                defaultSort: "desc",
                render: rowData => DateHighlightCell(rowData, "Created_Date"),
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "Cell",
                title: "Cell",
                render: rowData => HighlightCell(rowData, "Cell")
            },
            {
                field: "District",
                title: "District",
                render: rowData => HighlightCell(rowData, "District"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Job_Plan",
                title: "Job Plan",
                render: rowData => HighlightLookupCell(rowData, "Job_Plan"),
                lookup: this.lookupJobPlan,
                headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 }
            },
            {
                field: "WO_Status",
                title: "WO Status",
                render: rowData => HighlightLookupCell(rowData, "WO_Status"),
                lookup: this.lookupWOStatus,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Task_Status",
                title: "Task Status",
                render: rowData => HighlightLookupCell(rowData, "Task_Status"),
                lookup: this.lookupTaskStatus,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Scheduled_Start",
                title: "Scheduled Start Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Start"),
                headerStyle: { minWidth: 195 }, cellStyle: { minWidth: 195 }
            },
            {
                field: "Scheduled_Finish",
                title: "Scheduled Finish Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "Scheduled_Finish"),
                headerStyle: { minWidth: 205 }, cellStyle: { minWidth: 205 }
            },
            {
                field: "Project_WO_Code",
                title: "Project No",
                render: rowData => HighlightCell(rowData, "Project_WO_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Program_Code",
                title: "Program Code",
                render: rowData => HighlightCell(rowData, "Program_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Oracle_Code",
                title: "Oracle Code",
                render: rowData => HighlightCell(rowData, "Oracle_Code"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Days_Until_App_Start",
                title: "Days Until App start",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Days_Until_App_Start"),
                headerStyle: { minWidth: 190 }, cellStyle: { minWidth: 190 }
            },
            {
                field: "License_Arrange_Submitted_Date",
                title: "License Arrange Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Arrange_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Valid_Date",
                title: "License Valid Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Valid_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Expiry_Date",
                title: "License Expiry Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Expiry_Date"),
                headerStyle: { minWidth: 185 }, cellStyle: { minWidth: 185 }
            },
            {
                field: "License_Comment",
                title: "License Comment",
                render: rowData => HighlightCell(rowData, "License_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_1_Submitted_Date",
                title: "License Ext 1 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_1_Expiry",
                title: "License Ext 1 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_1_Expiry"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "License_Ext_1_Comment",
                title: "License Ext 1 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_1_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_2_Submitted_Date",
                title: "License Ext 2 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_2_Expiry",
                title: "License Ext 2 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_2_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_2_Comment",
                title: "License Ext 2 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_2_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "License_Ext_3_Submitted_Date",
                title: "License Ext 3 Submitted Date",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Submitted_Date"),
                headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }
            },
            {
                field: "License_Ext_3_Expiry",
                title: "License Ext 3 Expiry",
                type: "date",
                render: rowData => DateHighlightCell(rowData, "License_Ext_3_Expiry"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "License_Ext_3_Comment",
                title: "License Ext 3 Comment",
                render: rowData => HighlightCell(rowData, "License_Ext_3_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Pre_Con_Visit_Comment",
                title: "Pre Con Visit Comment",
                render: rowData => HighlightCell(rowData, "Pre_Con_Visit_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Traffic_Plan_Comment",
                title: "Traffic Plan Comment",
                render: rowData => HighlightCell(rowData, "Traffic_Plan_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Customer_App_Arranged_Comment",
                title: "Customer App Arranged Comment",
                render: rowData => HighlightCell(rowData, "Customer_App_Arranged_Comment"),
                headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }
            },
            {
                field: "Location_Code",
                title: "Location Code",
                type: 'numeric',
                render: rowData => HighlightCell(rowData, "Location_Code"),
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Crew_Description",
                title: "Crew Description",
                render: rowData => HighlightCell(rowData, "Crew_Description"),
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            },
            {
                field: "Licence_Arrange",
                title: "Licence Arrange",
                render: rowData => HighlightLookupCell(rowData, "Licence_Arrange"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Pre_Con_Pack",
                title: "Pre Con Pack",
                render: rowData => HighlightLookupCell(rowData, "Pre_Con_Pack"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Traffic_Plans",
                title: "Traffic Plans",
                render: rowData => HighlightLookupCell(rowData, "Traffic_Plans"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Addit_License_Conds",
                title: "Addit License Conds",
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                render: rowData => HighlightLookupCell(rowData, "Addit_License_Conds")
            },
            {
                field: "Cnstr_Pack_Issued",
                title: "Cnstr Pack Issued",
                render: rowData => HighlightLookupCell(rowData, "Cnstr_Pack_Issued"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }
            },
            {
                field: "Site_Work_Construction",
                title: "Site Work Construction",
                render: rowData => HighlightLookupCell(rowData, "Site_Work_Construction"),
                lookup: this.lookupCommon,
                headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 }
            }
        ];
        // const str = ';
        // const words = str.split("\t");
        // var output ="";
        // for(var col = 0; col < words.length; col++)
        // {
        //   var result ='{ field: "'+ words[col]+ '", title: "'+ (words[col].replace(/_/g,' '))+'" },';
        //   output=output+result;
        // }

    }
    getLookUpValue(prop, value) {
        switch (prop) {
            case "Job_Plan":
                return parseInt(getKey(this.lookupJobPlan, value));
            case "WO_Status":
                return parseInt(getKey(this.lookupWOStatus, value));
            case "Task_Status":
                return parseInt(getKey(this.lookupTaskStatus, value));
            case "Work_Type":
                return parseInt(getKey(this.lookupWorkTypes, value));
            default:
                return parseInt(getKey(this.lookupCommon, value));
        }
    }
    getDatabyType(prop, value) {
        if (this.numnerTypes.includes(prop)) {
            var val = parseInt(value);
            return isNaN(val) ? 0 : val;
        } else if (this.dateTypes.includes(prop)) {
            return getDateFormat(value);
        }
        else if (this.lookupTypes.includes(prop)) {
            return this.getLookUpValue(prop, value);
        }
        else {
            if (value !== null && value !== undefined) {
                return value.toString().trim();
            }
            else {
                return null;
            }
        }
    }

}
function HighlightCell(rowData, column) {
    return (Array.isArray(rowData.cellStyle) && rowData.cellStyle.length)
        ?
        rowData.cellStyle.includes(column)
            ? <p style={{ backgroundColor: '#FFF', color: '#FFA500' }}>{rowData[column]}</p>
            : rowData[column]
        : rowData[column]

}
function getLookUpValueByKey(prop, key) {
    var headers = null;
    switch (prop) {
        case "Job_Plan":
            headers = Headers.lookupJobPlan;
            break;
        case "WO_Status":
            headers = Headers.lookupWOStatus;
            break;
        case "Task_Status":
            headers = Headers.lookupTaskStatus;
            break;
        case "Work_Type":
            headers = Headers.lookupWorkTypes;
            break;
        default:
            headers = Headers.lookupCommon;
            break;
    }
    return headers[key];
}
function HighlightLookupCell(rowData, column) {
    var data = getLookUpValueByKey(column, rowData[column]);
    return (Array.isArray(rowData.cellStyle) && rowData.cellStyle.length)
        ?
        rowData.cellStyle.includes(column)
            ? <p style={{ backgroundColor: '#FFF', color: '#FFA500' }}>{data}</p>
            : data
        : data

}
function DateHighlightCell(rowData, column) {
    if (rowData[column])
        return (Array.isArray(rowData.cellStyle) && rowData.cellStyle.length)
            ?
            rowData.cellStyle.includes(column)
                ? <div style={{ backgroundColor: '#FFF', color: '#FFA500' }}><Moment local format="DD/MM/YYYY">{rowData[column]}</Moment></div>
                : <Moment local format="DD/MM/YYYY">{rowData[column]}</Moment>
            : <Moment local format="DD/MM/YYYY">{rowData[column]}</Moment>
    else
        return ""
}
function DateCell(rowData, column) {
    if (rowData[column])
        return <Moment local format="DD/MM/YYYY">{rowData[column]}</Moment>
    else
        return ""
}
function getDateFormat(date) {
    if (date === undefined || date === null || date === '' || (new Date(date)).toString() === "Invalid Date") {
        return null;
    }
    else {
        return new Date(date).toISOString();
    }
}


const getKey = (obj, val) => (Object.keys(obj).find(key => obj[key] === val)) === undefined ? 0 : Object.keys(obj).find(key => obj[key] === val);

export default new ContractorPlanningHeaders();