import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { withStyles, Grid, FormControl, TableRow, TableHead, TableCell, Button } from '@material-ui/core';
import Service from "../Networking/networkutils";
import Headers from '../ContractorPlanning/Headers';
import { withSnackbar } from 'notistack';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import Moment from 'react-moment';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PreConstructionForm from "../PreConstructionForm/preconstructionform";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FilterComponent from '../Common/FilterComponent';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const useStyles = (theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(0.25)
    },
    dateField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(-1),
        padding: theme.spacing(0.25)
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

const tdClassName = "MuiTableCell-root";
const clspaddingTop = "15px";
const clspaddingBottom = "15px";
const clspaddingLeft = "0px";
const clspaddingRight = "0px";
const clsheight = "35px";

function Distinct(arrayList) {
    return arrayList.filter(function (item, index, self) {
        return self.indexOf(item) === index;
    });
};

class GanttChart extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        var today = new Date();
        var allDays = this.getAllDay(today);
        this.state = {
            planningtoolData: [],
            isLoadingTable: true,
            lookupAgent: {},
            lookupCrew: {},
            lookupCells: {},
            crewAPI: false,
            failedcrewAPI: false,
            StartDate: allDays.startDt,
            EndDate: allDays.endDt,
            Day1: allDays.d1.toDateString(),
            Day2: allDays.d2.toDateString(),
            Day3: allDays.d3.toDateString(),
            Day4: allDays.d4.toDateString(),
            Day5: allDays.d5.toDateString(),
            Day6: allDays.d6.toDateString(),
            Day7: allDays.d7.toDateString(),
            selectedRow: {},
            OpenDialog: false,
            selectedCellsValue: [],
            UniqueCells: [],
            AllCells: [],
            selectedAgentsValue: [],
            UniqueAgents: [],
            AllAgents: [],
            selectedCrewValue: [],
            UniqueCrews: [],
            AllCrews: [],
            order: "asc",
            orderBy: "Full_Address"
        }
    }
    OpenDialogWindow = (data) => {
        this.setState({ OpenDialog: true, selectedRow: data });
    }

    GetTableCells = (data) => {
        return (
            <TableRow>
                <TableCell style={{ cursor: 'pointer' }}>{<AssignmentTurnedInIcon onClick={(event) => this.OpenDialogWindow(data)} />}</TableCell>
                {/* <TableCell>{Headers.lookupWorkTypes[data.Work_Type]}</TableCell> */}
                <TableCell>{data.Work_Order}</TableCell>
                <TableCell>{Headers.lookupJobPlan[data.Job_Plan]}</TableCell>
                <TableCell>{data.Full_Address}</TableCell>
                <TableCell>{data.License_Type}</TableCell>
                <TableCell>{data.Licence_reference_no}</TableCell>
                <TableCell>{Headers.lookupRestrictedHours[data.Restricted_hours]}</TableCell>
                <TableCell>{data.Special_conditions_or_remarks}</TableCell>
                <TableCell>{this.state.lookupCells[data.cells_by]}</TableCell>
                <TableCell>{this.state.lookupAgent[data.cells_by]}</TableCell>
                <TableCell>{this.state.lookupCrew[data.cells_by]}</TableCell>
                <TableCell> {(data.start_date) ? <Moment local format="DD/MM/YYYY HH:mm">{data.start_date}</Moment> : null} </TableCell>
                <TableCell>{(data.finish_date) ? <Moment local format="DD/MM/YYYY HH:mm">{data.finish_date}</Moment> : null}</TableCell>
                {(data.start_date)
                    ?
                    <>
                        {this.getRow(this.state.Day1, data.start_date, data.finish_date)}
                        {this.getRow(this.state.Day2, data.start_date, data.finish_date)}
                        {this.getRow(this.state.Day3, data.start_date, data.finish_date)}
                        {this.getRow(this.state.Day4, data.start_date, data.finish_date)}
                        {this.getRow(this.state.Day5, data.start_date, data.finish_date)}
                        {this.getRow(this.state.Day6, data.start_date, data.finish_date)}
                        {this.getRow(this.state.Day7, data.start_date, data.finish_date)}
                    </>
                    :
                    <>
                        { this.getCell(true, "#FFF", 11)}
                        { this.getCell(true, "#FFF", 11)}
                        { this.getCell(true, "#FFF", 11)}
                        { this.getCell(true, "#FFF", 11)}
                        { this.getCell(true, "#FFF", 11)}
                        { this.getCell(true, "#FFF", 11)}
                        { this.getCell(true, "#FFF", 11)}
                    </>
                }
            </TableRow>
        );
    }
    groupBy = (list, key) => {
        var result = [];
        list.map(val => {
            if (!result.find(s => s.Name === val[key])) {
                result.push({ Name: val[key], Values: list.filter(data => data[key] === val[key]).map(m => m.value) });
            }
            return null;
        });
        return result;
    }

    fetchCellsbyCrew() {
        Service.get('/bb/api/cellsby/')
            .then(res => {
                if (this._isMounted) {

                    var cellObjData = {};
                    var crewObjData = {};
                    var agentObjData = {};

                    var uniqueCells = Distinct(res.data.map(m => m.cell).sort());
                    var uniqueAgents = Distinct(res.data.map(m => m.agent).sort());
                    var uniqueCrews = Distinct(res.data.map(m => m.crew).sort());

                    var allcells = res.data.map(function (item) { return { name: item.cell, value: item.id }; });
                    var allagents = res.data.map(function (item) { return { name: item.agent, value: item.id }; });
                    var allcrews = res.data.map(function (item) { return { name: item.crew, value: item.id }; });

                    for (let i = 0; i < res.data.length; i++) {
                        cellObjData[res.data[i].id] = res.data[i].cell;
                        crewObjData[res.data[i].id] = res.data[i].crew;
                        agentObjData[res.data[i].id] = res.data[i].agent;
                    }

                    this.setState({
                        lookupAgent: agentObjData,
                        lookupCells: cellObjData,
                        lookupCrew: crewObjData,
                        crewAPI: true,
                        UniqueCells: uniqueCells,
                        AllCells: allcells,
                        UniqueAgents: uniqueAgents,
                        AllAgents: allagents,
                        UniqueCrews: uniqueCrews,
                        AllCrews: allcrews,
                    });

                    this.getData();
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                this.setState({ failedcrewAPI: true });
                if (this.state.failedcrewAPI) {
                    this.setState({ isLoadingTable: false });
                }
            });

    }
    getData() {
        if (this.state.crewAPI) {
            var startDt = this.state.StartDate;
            var enddt = this.state.EndDate;
            Service.get('/bb/api/planning2/noprecon/?start_date__gte=' + startDt + '&finish_date__lte=' + enddt)
                .then(res => {
                    if (this._isMounted) {
                        var data = this.stableSort(res.data, this.getComparator(this.state.order, this.state.orderBy));
                        this.setState({ planningtoolData: data, isLoadingTable: false, filteredData: data });
                    }
                })
                .catch(error => {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                    this.setState({ isLoadingTable: false });
                    if (this.state.failedcrewAPI) {
                        this.setState({ isLoadingTable: false });
                    }
                });
        }
    }
    getTotalHours(endDateTime, startDateTime) {
        var diff = (endDateTime.getTime() - startDateTime.getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs(Math.round(diff));
    }
    getRow(colDate, startDt, endDt) {
        if (colDate) {
            var colDt = new Date(colDate);
            const color = (colDt.getDay() === 6 || colDt.getDay() === 0) ? "#d3d3d3" : "#01579b";
            var stDt = new Date(startDt);
            var finishDt = new Date(endDt);
            var totalHours = this.getTotalHours(finishDt, stDt);
            var startDateTime = stDt.getHours();
            var endDateTime = finishDt.getHours();
            var startDtCell = this.getCellsByHour(startDateTime);
            var endDtCell = this.getCellsByHour(endDateTime);
            if (colDt >= new Date(stDt.toDateString()) && colDt <= new Date(finishDt.toDateString())) {
                if (totalHours >= 24) {
                    if (colDt.toLocaleDateString() === stDt.toLocaleDateString()) {
                        return (
                            <>
                                {this.getCell(startDtCell <= 1, color)}
                                {this.getCell(startDtCell <= 2, color)}
                                {this.getCell(startDtCell <= 3, color)}
                                {this.getCell(startDtCell <= 4, color)}
                                {this.getCell(startDtCell <= 5, color)}
                                {this.getCell(startDtCell <= 6, color)}
                                {this.getCell(startDtCell <= 7, color)}
                                {this.getCell(startDtCell <= 8, color)}
                                {this.getCell(startDtCell <= 9, color)}
                                {this.getCell(startDtCell <= 10, color)}
                                {this.getCell(startDtCell <= 11, "#f3c620")}
                            </>
                        );
                    }
                    else if (colDt.toLocaleDateString() === finishDt.toLocaleDateString()) {
                        return (
                            <>
                                {this.getCell(endDtCell >= 1, color)}
                                {this.getCell(endDtCell >= 2, color)}
                                {this.getCell(endDtCell >= 3, color)}
                                {this.getCell(endDtCell >= 4, color)}
                                {this.getCell(endDtCell >= 5, color)}
                                {this.getCell(endDtCell >= 6, color)}
                                {this.getCell(endDtCell >= 7, color)}
                                {this.getCell(endDtCell >= 8, color)}
                                {this.getCell(endDtCell >= 9, color)}
                                {this.getCell(endDtCell >= 10, color)}
                                {this.getCell(endDtCell >= 11, "#f3c620")}
                            </>
                        );
                    }
                    else {
                        return (
                            <>
                                { this.getCell(true, color, 10)}
                                { this.getCell(true, "#f3c620")}
                            </>
                        )
                    }
                }
                else {
                    return (
                        <>
                            {this.getCell(startDtCell <= 1 && endDtCell >= 1, color)}
                            {this.getCell(startDtCell <= 2 && endDtCell >= 2, color)}
                            {this.getCell(startDtCell <= 3 && endDtCell >= 3, color)}
                            {this.getCell(startDtCell <= 4 && endDtCell >= 4, color)}
                            {this.getCell(startDtCell <= 5 && endDtCell >= 5, color)}
                            {this.getCell(startDtCell <= 6 && endDtCell >= 6, color)}
                            {this.getCell(startDtCell <= 7 && endDtCell >= 7, color)}
                            {this.getCell(startDtCell <= 8 && endDtCell >= 8, color)}
                            {this.getCell(startDtCell <= 9 && endDtCell >= 9, color)}
                            {this.getCell(startDtCell <= 10 && endDtCell >= 10, color)}
                            {this.getCell(startDtCell <= 11 && endDtCell >= 11, "#f3c620")}
                        </>
                    );
                }
            }
            else {
                return this.getCell(true, "#FFF", 11);
            }
        }
    }
    getCell(condition, color, colSpan = null) {
        return (
            <td className={tdClassName} colSpan={colSpan} style={{ paddingTop: clspaddingTop, paddingBottom: clspaddingBottom, paddingLeft: clspaddingLeft, paddingRight: clspaddingRight }}>
                <div style={{ backgroundColor: (condition) ? color : "#FFF", backgroundClip: 'content-box', height: clsheight }} >
                </div>
            </td>
        );
    }
    getCellsByHour(hour) {
        switch (hour) {
            case 8:
                return 1;
            case 9:
                return 2;
            case 10:
                return 3;
            case 11:
                return 4;
            case 12:
                return 5;
            case 13:
                return 6;
            case 14:
                return 7;
            case 15:
                return 8;
            case 16:
                return 9;
            case 17:
                return 10;
            default:
                if (hour > 17) {
                    return 11;
                }
                return 0;
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this.fetchCellsbyCrew();
        this.getData();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleOpenDialog = (e) => {
        this.setState({ OpenDialog: true });
    }
    handleCloseDialog = (e) => {
        this.setState({ OpenDialog: false });
    };
    getAllDay(today) {
        var d1 = new Date(today.setDate(today.getDate() - today.getDay()));
        var d2 = new Date(today.setDate(today.getDate() - today.getDay() + 1));
        var d3 = new Date(today.setDate(today.getDate() - today.getDay() + 2));
        var d4 = new Date(today.setDate(today.getDate() - today.getDay() + 3));
        var d5 = new Date(today.setDate(today.getDate() - today.getDay() + 4));
        var d6 = new Date(today.setDate(today.getDate() - today.getDay() + 5));
        var d7 = new Date(today.setDate(today.getDate() - today.getDay() + 6));
        var startDt = new Date(d1.setHours(0, 0, 0, 0)).toISOString();//UTC
        var endDt = new Date(d7.setHours(23, 59, 59)).toISOString();//UTC
        var obj = { d1, d2, d3, d4, d5, d6, d7, startDt, endDt };
        return obj;
    }
    setFilteredData = data => {
        this.setState({ filteredData: data });
    }
    setSelectedCellsValue = data => {
        this.setState({ selectedCellsValue: data });
    }
    setSelectedAgentsValue = data => {
        this.setState({ selectedAgentsValue: data });
    }
    setSelecteCrewsValue = data => {
        this.setState({ selectedCrewValue: data });
    }
    getSelectedCellsValue = data => {
        return this.state.selectedCellsValue;
    }
    getSelectedAgentsValue = data => {
        return this.state.selectedAgentsValue;
    }
    getSelecteCrewsValue = data => {
        return this.state.selectedCrewValue;
    }
    showLoader = event => {
        this.setState({ isLoadingTable: true });
    }
    hideLoader = event => {
        this.setState({ isLoadingTable: false });
    }
    descendingComparator = (a, b, orderBy) => {
        a[orderBy] = !a[orderBy] ? " " : a[orderBy];
        b[orderBy] = !b[orderBy] ? " " : b[orderBy];
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        return 0;
    }
    getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => this.descendingComparator(a, b, orderBy)
            : (a, b) => -this.descendingComparator(a, b, orderBy);
    }

    stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        var sortedData = stabilizedThis.map((el) => el[0]);
        return sortedData;
    }

    createSortHandler = (property) => (event) => {
        this.setState({ isLoadingTable: true });
        var order_orderBy = this.handleRequestSort(event, property);
        this.setState({ order: order_orderBy.order, orderBy: order_orderBy.orderBy }, () => {
            var sortedData = this.stableSort(this.state.filteredData, this.getComparator(this.state.order, this.state.orderBy));
            this.setState({
                planningtoolData: sortedData,
                filteredData: sortedData,
                isLoadingTable: false
            });
        });
    };

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        return { order: isAsc ? 'desc' : 'asc', orderBy: property };
    };

    render() {

        const { classes } = this.props;

        const handleStartDate = (date) => {
            var d1 = new Date(date);
            var d2 = new Date(date).setDate(date.getDate() + 1);
            var d3 = new Date(date).setDate(date.getDate() + 2);
            var d4 = new Date(date).setDate(date.getDate() + 3);
            var d5 = new Date(date).setDate(date.getDate() + 4);
            var d6 = new Date(date).setDate(date.getDate() + 5);
            var d7 = new Date(date).setDate(date.getDate() + 6);
            var endDt = new Date(d7);
            this.setState({
                StartDate: new Date(d1.setHours(0, 0, 0, 0)).toISOString(),//UTC
                EndDate: new Date(endDt.setHours(23, 59, 59)).toISOString(),//UTC
                Day1: new Date(d1).toDateString(),
                Day2: new Date(d2).toDateString(),
                Day3: new Date(d3).toDateString(),
                Day4: new Date(d4).toDateString(),
                Day5: new Date(d5).toDateString(),
                Day6: new Date(d6).toDateString(),
                Day7: new Date(d7).toDateString()
            }, () => { this.getData(); });
        };
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <FormControl size="small" variant="outlined" className={useStyles.textField} fullWidth>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker className={useStyles.dateField} style={{ width: "100%" }} name="StartDate" label="Select date" format="dd/MM/yyyy" value={this.state.StartDate} onChange={handleStartDate} KeyboardButtonProps={{ 'aria-label': 'Change date', }} />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <MaterialTable
                            title="Gantt Chart"
                            isLoading={this.state.isLoadingTable}
                            columns={[
                                { field: 'Action', editable: 'never', width: 60 },
                                { title: 'WO #', field: 'Work_Order', editable: 'never', width: 100 },
                                { title: 'Job Type', field: 'Job_Plan', lookup: Headers.lookupJobPlan, editable: 'never', width: 100 },
                                { title: 'Address', field: 'Full_Address', editable: 'never', width: 530 },
                                { title: 'Licence Type', field: 'License_Type', editable: 'never', width: 125 },
                                { title: 'Licence No', field: 'Licence_reference_no', editable: 'never', width: 125 },
                                { title: 'Restricted Hours', field: 'Restricted_hours', editable: 'never', lookup: Headers.lookupRestrictedHours, width: 150 },
                                { title: 'Remarks', field: 'Special_conditions_or_remarks', editable: 'never', width: 530 },
                                {
                                    title: 'Cell', field: 'cells_by', editable: 'never', width: 75,
                                    filterComponent: (props) => {
                                        return (
                                            (this.state.crewAPI) ? <FilterComponent
                                                selectedValue={this.state.selectedCellsValue}
                                                UniqueValues={this.state.UniqueCells}
                                                filteredData={this.setFilteredData}
                                                AllCells={this.state.AllCells}
                                                AllAgents={this.state.AllAgents}
                                                AllCrews={this.state.AllCrews}
                                                ShowLoader={this.showLoader}
                                                HideLoader={this.hideLoader}
                                                SelectedCellValue={this.getSelectedCellsValue}
                                                SelectedAgentValue={this.getSelectedAgentsValue}
                                                SelectedCrewValue={this.getSelecteCrewsValue}
                                                planningtoolData={this.state.planningtoolData}
                                                SetSelectedValue={this.setSelectedCellsValue} /> : (null)
                                        );
                                    },
                                },
                                {
                                    title: 'Agent', field: 'cells_by', editable: 'never',
                                    filterComponent: (props) => {
                                        return (
                                            (this.state.crewAPI) ? <FilterComponent
                                                selectedValue={this.state.selectedAgentsValue}
                                                UniqueValues={this.state.UniqueAgents}
                                                filteredData={this.setFilteredData}
                                                AllCells={this.state.AllCells}
                                                AllAgents={this.state.AllAgents}
                                                AllCrews={this.state.AllCrews}
                                                ShowLoader={this.showLoader}
                                                HideLoader={this.hideLoader}
                                                SelectedCellValue={this.getSelectedCellsValue}
                                                SelectedAgentValue={this.getSelectedAgentsValue}
                                                SelectedCrewValue={this.getSelecteCrewsValue}
                                                planningtoolData={this.state.planningtoolData}
                                                SetSelectedValue={this.setSelectedAgentsValue} /> : (null)
                                        );
                                    }, width: 175
                                },
                                {
                                    title: 'Crew', field: 'cells_by',
                                    filterComponent: (props) => {
                                        return (
                                            (this.state.crewAPI) ? <FilterComponent
                                                selectedValue={this.state.selectedCrewValue}
                                                UniqueValues={this.state.UniqueCrews}
                                                filteredData={this.setFilteredData}
                                                AllCells={this.state.AllCells}
                                                AllAgents={this.state.AllAgents}
                                                AllCrews={this.state.AllCrews}
                                                ShowLoader={this.showLoader}
                                                HideLoader={this.hideLoader}
                                                SelectedCellValue={this.getSelectedCellsValue}
                                                SelectedAgentValue={this.getSelectedAgentsValue}
                                                SelectedCrewValue={this.getSelecteCrewsValue}
                                                planningtoolData={this.state.planningtoolData}
                                                SetSelectedValue={this.setSelecteCrewsValue} /> : (null)
                                        );
                                    }, width: 200
                                },
                                { title: 'Start Date & Time', field: 'start_date', type: "datetime", width: 150 },
                                { title: 'Finish Date & Time', field: 'finish_date', type: "datetime", editable: 'never', width: 160 },
                            ]}
                            data={this.state.filteredData}
                            options={{
                                pageSize: 20,
                                search: true,
                                filtering: true,
                                // doubleHorizontalScroll: true,
                                // sorting: false,
                                // headerStyle: { position: 'sticky', top: 0 },
                                // maxBodyHeight: Math.round(window.screen.height - window.screen.height * 0.37),
                                fixedColumns: {
                                    left: 4,
                                    right: 0
                                },
                                actionsColumnIndex: -1
                            }}
                            components={{
                                Header: props => {
                                    return (
                                        <>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ minWidth: 60, width: 60 }} rowSpan={2}>Action</TableCell>
                                                    {/* <TableCell style={{ minWidth: 250, width: 250 }} rowSpan={2}>Tab name</TableCell> */}
                                                    <TableCell style={{ minWidth: 100, width: 100 }} rowSpan={2} key={"Work_Order"} sortDirection={this.state.orderBy === "Work_Order" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "Work_Order"} direction={this.state.orderBy === "Work_Order" ? this.state.order : 'asc'} onClick={this.createSortHandler("Work_Order")}>WO #
                                                        {this.state.orderBy === "Work_Order" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 100, width: 100 }} rowSpan={2} key={"Job_Plan"} sortDirection={this.state.orderBy === "Job_Plan" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "Job_Plan"} direction={this.state.orderBy === "Job_Plan" ? this.state.order : 'asc'} onClick={this.createSortHandler("Job_Plan")}>Job Type
                                                            {this.state.orderBy === "Job_Plan" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 530, width: 530 }} rowSpan={2} key={"Full_Address"} sortDirection={this.state.orderBy === "Full_Address" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "Full_Address"} direction={this.state.orderBy === "Full_Address" ? this.state.order : 'asc'} onClick={this.createSortHandler("Full_Address")}>Address
                                                        {this.state.orderBy === "Full_Address" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 125, width: 125 }} rowSpan={2} key={"License_Type"} sortDirection={this.state.orderBy === "License_Type" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "License_Type"} direction={this.state.orderBy === "License_Type" ? this.state.order : 'asc'} onClick={this.createSortHandler("License_Type")}>Licence Type
                                                        {this.state.orderBy === "License_Type" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 125, width: 125 }} rowSpan={2} key={"Licence_reference_no"} sortDirection={this.state.orderBy === "Licence_reference_no" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "Licence_reference_no"} direction={this.state.orderBy === "Licence_reference_no" ? this.state.order : 'asc'} onClick={this.createSortHandler("Licence_reference_no")}>Licence No
                                                            {this.state.orderBy === "Licence_reference_no" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 150, width: 150 }} rowSpan={2} key={"Restricted_hours"} sortDirection={this.state.orderBy === "Restricted_hours" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "Restricted_hours"} direction={this.state.orderBy === "Restricted_hours" ? this.state.order : 'asc'} onClick={this.createSortHandler("Restricted_hours")}>Restricted Hours
                                                        {this.state.orderBy === "Restricted_hours" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 530, width: 530 }} rowSpan={2} key={"Special_conditions_or_remarks"} sortDirection={this.state.orderBy === "Special_conditions_or_remarks" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "Special_conditions_or_remarks"} direction={this.state.orderBy === "Special_conditions_or_remarks" ? this.state.order : 'asc'} onClick={this.createSortHandler("Special_conditions_or_remarks")}>Remarks
                                                        {this.state.orderBy === "Special_conditions_or_remarks" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 75, width: 75 }} rowSpan={2} key={"cells_by"} sortDirection={this.state.orderBy === "cells_by" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "cells_by"} direction={this.state.orderBy === "cells_by" ? this.state.order : 'asc'} onClick={this.createSortHandler("cells_by")}>Cell
                                                        {this.state.orderBy === "cells_by" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 175, width: 175 }} rowSpan={2} key={"Agent"} sortDirection={this.state.orderBy === "Agent" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "Agent"} direction={this.state.orderBy === "Agent" ? this.state.order : 'asc'} onClick={this.createSortHandler("cells_by")}>Agent
                                                        {this.state.orderBy === "Agent" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 200, width: 200 }} rowSpan={2} key={"Crew"} sortDirection={this.state.orderBy === "Crew" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "Crew"} direction={this.state.orderBy === "Crew" ? this.state.order : 'asc'} onClick={this.createSortHandler("cells_by")}>Crew
                                                        {this.state.orderBy === "Crew" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 150, width: 150 }} rowSpan={2} key={"start_date"} sortDirection={this.state.orderBy === "start_date" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "start_date"} direction={this.state.orderBy === "start_date" ? this.state.order : 'asc'} onClick={this.createSortHandler("start_date")}>Start Date & Time
                                                        {this.state.orderBy === "start_date" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 160, width: 160 }} rowSpan={2} key={"finish_date"} sortDirection={this.state.orderBy === "finish_date" ? this.state.order : false}>
                                                        <TableSortLabel active={this.state.orderBy === "finish_date"} direction={this.state.orderBy === "finish_date" ? this.state.order : 'asc'} onClick={this.createSortHandler("finish_date")}>Finish Date & Time
                                                        {this.state.orderBy === "finish_date" ? (<span className={classes.visuallyHidden}>{this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell style={{ width: 500 }} colSpan={11} align="center">{this.state.Day1}</TableCell>
                                                    <TableCell style={{ width: 500 }} colSpan={11} align="center">{this.state.Day2}</TableCell>
                                                    <TableCell style={{ width: 500 }} colSpan={11} align="center">{this.state.Day3}</TableCell>
                                                    <TableCell style={{ width: 500 }} colSpan={11} align="center">{this.state.Day4}</TableCell>
                                                    <TableCell style={{ width: 500 }} colSpan={11} align="center">{this.state.Day5}</TableCell>
                                                    <TableCell style={{ width: 500 }} colSpan={11} align="center">{this.state.Day6}</TableCell>
                                                    <TableCell style={{ width: 500 }} colSpan={11} align="center">{this.state.Day7}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center" style={{ minWidth: 160, borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>8 AM</TableCell>
                                                    <TableCell style={{ minWidth: 75, width: 75 }} >9 AM</TableCell>
                                                    <TableCell>10 AM</TableCell>
                                                    <TableCell>11 AM</TableCell>
                                                    <TableCell>12 PM</TableCell>
                                                    <TableCell>1 PM</TableCell>
                                                    <TableCell>2 PM</TableCell>
                                                    <TableCell>3 PM</TableCell>
                                                    <TableCell>4 PM</TableCell>
                                                    <TableCell>5 PM</TableCell>
                                                    <TableCell align="center" style={{ minWidth: 160, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Out of Office</TableCell>
                                                    <TableCell>8 AM</TableCell>
                                                    <TableCell>9 AM</TableCell>
                                                    <TableCell>10 AM</TableCell>
                                                    <TableCell>11 AM</TableCell>
                                                    <TableCell>12 PM</TableCell>
                                                    <TableCell>1 PM</TableCell>
                                                    <TableCell>2 PM</TableCell>
                                                    <TableCell>3 PM</TableCell>
                                                    <TableCell>4 PM</TableCell>
                                                    <TableCell>5 PM</TableCell>
                                                    <TableCell align="center" style={{ minWidth: 160, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Out of Office</TableCell>
                                                    <TableCell>8 AM</TableCell>
                                                    <TableCell>9 AM</TableCell>
                                                    <TableCell>10 AM</TableCell>
                                                    <TableCell>11 AM</TableCell>
                                                    <TableCell>12 PM</TableCell>
                                                    <TableCell>1 PM</TableCell>
                                                    <TableCell>2 PM</TableCell>
                                                    <TableCell>3 PM</TableCell>
                                                    <TableCell>4 PM</TableCell>
                                                    <TableCell>5 PM</TableCell>
                                                    <TableCell align="center" style={{ minWidth: 160, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Out of Office</TableCell>
                                                    <TableCell >8 AM</TableCell>
                                                    <TableCell >9 AM</TableCell>
                                                    <TableCell >10 AM</TableCell>
                                                    <TableCell >11 AM</TableCell>
                                                    <TableCell >12 PM</TableCell>
                                                    <TableCell >1 PM</TableCell>
                                                    <TableCell >2 PM</TableCell>
                                                    <TableCell >3 PM</TableCell>
                                                    <TableCell >4 PM</TableCell>
                                                    <TableCell >5 PM</TableCell>
                                                    <TableCell align="center" style={{ minWidth: 160, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Out of Office</TableCell>
                                                    <TableCell >8 AM</TableCell>
                                                    <TableCell >9 AM</TableCell>
                                                    <TableCell >10 AM</TableCell>
                                                    <TableCell >11 AM</TableCell>
                                                    <TableCell >12 PM</TableCell>
                                                    <TableCell >1 PM</TableCell>
                                                    <TableCell >2 PM</TableCell>
                                                    <TableCell >3 PM</TableCell>
                                                    <TableCell >4 PM</TableCell>
                                                    <TableCell >5 PM</TableCell>
                                                    <TableCell align="center" style={{ minWidth: 160, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Out of Office</TableCell>
                                                    <TableCell >8 AM</TableCell>
                                                    <TableCell >9 AM</TableCell>
                                                    <TableCell >10 AM</TableCell>
                                                    <TableCell >11 AM</TableCell>
                                                    <TableCell >12 PM</TableCell>
                                                    <TableCell >1 PM</TableCell>
                                                    <TableCell >2 PM</TableCell>
                                                    <TableCell >3 PM</TableCell>
                                                    <TableCell >4 PM</TableCell>
                                                    <TableCell >5 PM</TableCell>
                                                    <TableCell align="center" style={{ minWidth: 160, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Out of Office</TableCell>
                                                    <TableCell >8 AM</TableCell>
                                                    <TableCell >9 AM</TableCell>
                                                    <TableCell >10 AM</TableCell>
                                                    <TableCell >11 AM</TableCell>
                                                    <TableCell >12 PM</TableCell>
                                                    <TableCell >1 PM</TableCell>
                                                    <TableCell >2 PM</TableCell>
                                                    <TableCell >3 PM</TableCell>
                                                    <TableCell >4 PM</TableCell>
                                                    <TableCell >5 PM</TableCell>
                                                    <TableCell align="center" style={{ minWidth: 160, borderRight: '1px solid rgba(224, 224, 224, 1)' }} >Out of Office</TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </>
                                    );
                                },
                                Row: ({ data }) => {
                                    return (
                                        this.GetTableCells(data)
                                    );
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Dialog fullScreen
                    open={this.state.OpenDialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Pre-Construction Form"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText component="div">
                            <AppBar position="fixed">
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.handleCloseDialog} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" component="span">
                                        Pre-Construction Form
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <PreConstructionForm selectedRow={this.state.selectedRow} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}
export default withStyles(useStyles)(withSnackbar(GanttChart));